
import axios from 'axios';


export async function getHeatmap(indicator) {

    const url  = `/api/heatmap/${indicator}`;
    const res = await axios.get(url);
    return res.data;
}

export async function getFeatureEstate(estate_sn) {

    const url  = `/api/geomap/estate/${estate_sn}`;
    const res = await axios.get(url);
    return res.data;
}

export async function loadIndicatorRank(indicator, monthNo) {
    
    let url;
    if (monthNo !== undefined) {
        url = `/api/geomap/rank/${indicator}/${monthNo}`;
    } else {
        url = `/api/geomap/rank/${indicator}`;
    }

    const res = await axios.get(url);
    return res.data;
}

export async function loadIndicatorTrend(indicator) {
    
    let url;
    url = `/api/geomap/trend/${indicator}`;
    const res = await axios.get(url);
    return res.data;
}

export async function loadDevlopQuantile(monthNo) {
    
    let url;
    if (monthNo !== undefined) {
        url = `/api/geomap/develop/${monthNo}`;
    } else {
        url = `/api/geomap/develop`;
    }

    const res = await axios.get(url);
    return res.data;
}

