import { select as d3Select } from "d3-selection";
import { axisBottom, axisRight } from "d3-axis";
import { scaleLinear, scaleThreshold } from "d3-scale";
import { extent as arrayExtent } from "d3-array";

export default class Axis {
    constructor(domNode) {
        this.svg = d3Select(domNode).append("svg");
        this.svg //
            .attr("width", "100%")
            .attr("height", "100%");

        this.rootDomNode = domNode;
    }

    init(data, dims) {
        this.data = data;
        this.dims = dims;
        this.chart = this.svg.append("g");
        this.chart.attr(
            "transform",
            `translate(${this.dims.margin.left}, ${this.dims.margin.top})`
        );

        // const yarray = arrayExtent(data.map(d => d["growth"]));

        this.yScale = scaleLinear()
            .domain(arrayExtent(data.map(d => d["growth"])))
            .range([dims.innerHeight, 0])
            .nice();

        this.yAxisRight = axisRight()
            .scale(this.yScale)
            .ticks(10);

        this.chart.call(this.yAxisRight);

        // this.axes = new Axes(this.chart, data, this.dims); //second

        // this.updateData(data, this.axes);
    }

    updateData(data) {
        //
    }

    updateDims(dims) {
        //
    }
}
