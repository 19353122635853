import React from 'react'
import styled from 'styled-components';
import { keyframes } from 'styled-components';


export default function BoucingLoading(props) {
	return (
		<StyledDiv className="loading-icon">
			<span></span>
			<span></span>
			<span></span>
		</StyledDiv>
	);
}

const bouncingLoader = keyframes`
  	from {
    	width: 2px;
    	height: 2px;
    	opacity: 1;
    	transform: translate3d(0);
  	}
  
  	to {
    	width: 20px;
    	height: 20px;
    	opacity: 0.1;
    	transform: translate3d(0, -20px, 0);
  	}
`;

const StyledDiv = styled.div`
  	display: flex;
  	/* justify-content: center; */
	align-items: center;
	/* position */

    height: 60px;
    width: 120px;
    justify-content: space-around;

	& > span {
		position: relative;
		bottom: -10px;

		background: hsl(311, 60%, 50%);
		border-radius: 50%;

    	/* width: 2px;
    	height: 2px; */

		/* margin: 20px 10px; */
		animation: ${bouncingLoader} 0.6s infinite alternate;
	}

	& > span:nth-child(2) {
		animation-delay: 0.2s;
	}

	& > span:nth-child(3) {
		animation-delay: 0.4s;
	}
`;
