
import React, { useMemo } from 'react';
import {
    useState,
} from 'react';

import styled from 'styled-components';

import clsx from 'clsx';
import {
    Input,
    Tooltip,
} from 'antd';

// import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';


function isFilled(value) {
    return value != null &&
        !(Array.isArray(value) && value.length === 0) &&
        value !== '';
}

function renderError(errors) {
    return errors.map((e, index) => {

        let node = null;

        if (React.isValidElement(e)) {
            node = e;
        } else if (React.isValidElement(e.message)) {
            node = e.message;
        }

        // eslint-disable-next-line react/no-array-index-key
        return node ? React.cloneElement(node, { key: index }) : e.message;
    }).reduce((elems, node) => [...elems, ' ', node], []).slice(1);
}

function hasValue(value) {
    return value !== undefined && value !== null;
}

export function checkFieldValueChange(oldValue, newValue) {
    if (newValue === oldValue) {
        return false;
    } else {
        if ((oldValue === null || oldValue === undefined) &&
            (newValue === null || newValue.length === 0)) {
            // 输入域修改删除后是空字符串
            return false;
        }
    }

    return true;
}

export default function Field(props) {

    const {
        className,
        label,
        form,
        submission,
        name,
        children,
    } = props;

    const [focused, setFocused] = useState(false);

    let align = props.align;
    if (align !== 'right' && align !== 'center') {
        align = 'left';
    }

    if (form === undefined || typeof name !== 'string' || submission === undefined) {
        console.error('Field需要form, name, submission等属性');
    }

    const origin = (submission && submission.origin) || null;
    const change = (submission && submission.change) || null;
    const submitted = change && hasValue(change[name]);


    let initialValue = submission.present[name];
    if (initialValue === undefined) {
        initialValue = null;
    }


    const inputElem = useMemo(() => {
        let inputEl;
        
        inputEl = (children !== undefined) ? children : <Input />;
        
        inputEl = form.getFieldDecorator(name, {
            initialValue: initialValue,
        })(inputEl);

        return inputEl;
    }, [children, form, name, initialValue]);


    const inputValue = inputElem.props.value;
    const filled = isFilled(inputValue);
    const changed = checkFieldValueChange(initialValue, inputValue);

    let hasError;
    let message;
    const dataField = inputElem.props["data-__field"];
    if (dataField.errors) {
        hasError = true;
        message = renderError(dataField.errors);
    } else {
        hasError = false;
        message = null;
    }

    const originEl = useMemo(() => {
        if (hasValue(origin) && hasValue(origin[name])
            && (changed || (hasValue(change) && hasValue(change[name])))
        ) {
            const handleClick = (event) => {
                form.setFieldsValue({
                    [name]: change[name]
                });
            };

            return (
                <span className="origin">
                    <Tooltip title={origin[name]}>
                        <Button className="origin"
                            onClick={handleClick}>
                            原
                        </Button>                        
                    </Tooltip>
                </span>
            );
        }

        return null;
    }, [name, change, changed, origin, form]);

    const submittedEl = useMemo(() => {
        if (!(submitted && changed)) {
            return null;
        }

        const handleClick = (event) => {
            form.setFieldsValue({
                [name]: change[name]
            });
        };

        return (
            <Tooltip title={change[name]}>
                <Button className="submitted"
                    onClick={handleClick}>
                    改
                </Button>
            </Tooltip>
        );
    }, [name, change, changed, submitted, form]);


    return (
        <StyledField className={
            clsx(className,
                `form-field align-${align}`,
                filled && 'filled',
                focused && 'focused',
                hasError && 'error',
                changed && 'changed',
                submitted && 'submitted',
            )}>
            <label className="label" >
                <span>{label}</span>

            </label>
            <div className="input"
                onFocus={(event) => setFocused(true)}
                onBlur={(event) => setFocused(false)}>
                {inputElem}
            </div>
            <div className="activation">
                <hr className="line1" />
                <hr className="line2" />
            </div>
            <div className="message">
                <span>{message}</span>
                <div className="field-ctrlbar">
                    {originEl}
                    {submittedEl}
                </div>
            </div>
        </StyledField>
    );
}

const StyledField = styled.div`
    display: block;
    position: relative;
    background-color: transparent;
    font-family: Roboto, sans-serif;
    transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    cursor: auto;
    font-size: 16px;


    > label.label {
        position: absolute;
        font-size: 1em;
        width: 100%;

        top: 0.8em;
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        
        pointer-events: none;
        user-select: none;
        color: rgba(0, 0, 0, 0.3);
    }




    &.align-left {
        > label {
            transform-origin: left top;
            text-align: left; 
        }

        &.filled >  label {
            transform: translate(0, -0.9em) scale(0.75);
        }

        &.focused >  label {
            transform: translate(0, -0.9em) scale(0.75);
        }
    }

    &.align-right {
        > label {
            transform-origin: right top;
            text-align: right;
        }
        
        &.filled >  label {
            transform: translate(0, -0.9em) scale(0.75);
        }

        &.focused >  label {
            transform: translate(0, -0.9em) scale(0.75);
        }
    }

    &.changed label {
        color: hsl(183, 62%, 38%);
    }

    &.focused label {
        color: hsl(187, 100%, 42%);
    }


    > div.input {
        padding: 0px;
        position: relative;
        width: 100%;
        margin-left: 0.5em;
        margin-top: 0.8em;
        line-height: 1.8;

        input, .form-control { 
            padding: 0px;

            border: none;
            outline: none;
            background-color: rgba(0, 0, 0, 0);
            color: rgba(0, 0, 0, 0.57);
            cursor: inherit;
            font: inherit;
            opacity: 1;
            height: 100%;
            box-sizing: border-box;

            :focus {
                border-width: none;
                border-color: none;
                outline: 0;
                box-shadow: none;
            }

        }
    }

    .ant-select-selection, .ant-input-number {
        border: none;
        background-color: transparent;
        
        :focus, :active, :hover {
            border: none;
            border-color: none;
            box-shadow: none;
            outline: 0;
        }
    }

    .ant-input-number-focused {
        box-shadow: none;
    }

    .ant-input-number-handler {
        background-color: hsl(212,40%,63%);
        color: #fff;

        &.ant-input-number-handler-down {

        }
    }
    


    > .activation {
        > .line1 {
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 1px solid rgb(224, 224, 224);
            box-sizing: content-box;
            margin: 0px;
            position: absolute;
            width: 100%;
        }

        > .line2 {
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 2px solid rgb(0, 188, 212);
            box-sizing: content-box;
            margin: 0px;
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        }

    } 
    
    &.focused > .activation > .line2 {
        transform: scaleX(1);
    }

    .message {
        font-size: 0.7em;
        line-height: 1.3;
        height: 1.3em;

        .field-ctrlbar {
            float: right;
            /* font-size: 0.8em; */

            button {
                padding: 0px 2px;
                min-width: unset;
                font-size: 0.8em;
                font-weight: bold;
                margin: 0 1px;
                border-radius: 2px;
                line-height: 1.4;
            }

            > button.submitted  {
                background-color: hsl(120, 95%, 27%);
                color: #fff;

                :hover {
                    background-color: hsl(120, 55%, 43%)
                }
            }

            > button.origin {
                background-color: hsl(257, 95%, 27%);
                color: #fff;

                :hover {
                    background-color: hsl(257, 55%, 43%)
                }
            }
        }
    }

    &.form-field.changed   input {
        color: rgba(0, 0, 0, 0.90);;
    }
    

    &.form-field.submitted >   label {
        color: hsl(120, 95%, 27%);
    }
    
    &.error >   label {
        color: hsl(4, 90%, 58%);
    }

    &.error > .message {
        color: hsl(4, 90%, 58%);
    }
`;

