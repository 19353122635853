import React from "react";
import styled from "styled-components";

import { Switch, Route, Redirect } from "react-router-dom";

// import { useLocation } from 'react-router';

// import {
//     // TransitionGroup,
//     SwitchTransition,
//     CSSTransition,
// } from 'react-transition-group'
// import {
//     fadeInRight,
//     // fadeOutLeft,
//     zoomOut,
// } from "./animate";

import MainMap from "./map/MainMap";
import DashboardHome from "./dashboard/HomePage";

// const MainRoutes = styled((props) => {

//     const location = useLocation();
//     let { pathname } = location;

//     let pageKey;
//     if (pathname.startsWith('/stats')) {
//         pageKey = '/stats';
//     } else if (pathname.startsWith('/map')) {
//         pageKey = '/map';
//     } else {
//         pageKey = location.pathname + location.search
//     }

//     return (
//         <SwitchTransition mode="out-in">
//             <CSSTransition
//                 key={pageKey}
//                 timeout={600}
//                 unmountOnExit
//                 addEndListener={(node, done) => {
//                     // use the css transitionend event to mark the finish of a transition
//                     node.addEventListener("transitionend", done, false);
//                 }}
//             >
//                 <Switch>
//                     {/* <Route path="/submission/" component={SubmissionRoute} /> */}
//                     {/* <Route path="/firm/" component={FirmRoute} /> */}
//                     {/* <Route path="/estates" component={EstateView} /> */}
//                     {/* <Route path="/test" component={Test} /> */}
//                     <Route path="/map" component={MainMap} />
//                     {/* <Route path="/stats" component={Dashboard} /> */}
//                     <Redirect path="/" to="/map" />
//                 </Switch>
//             </CSSTransition>
//         </SwitchTransition>
//     );
// })`
// `;

export default function MainView(props) {
    return (
        <StyledMainView className="main-view">
            <Switch>
                <Route path="/map/" component={MainMap} />
                <Route path="/dashboard" component={DashboardHome} />
                <Route>
                    <Redirect to="/dashboard" />
                </Route>
            </Switch>
        </StyledMainView>
    );
}

// https://colors.ichuantong.cn/ 中国色选色
const StyledMainView = styled.div`
    width: 100%;
    height: 100%;
    background-color: hsl(225, 67%, 98%);
`;

