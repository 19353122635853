import { useReducer } from "react";

const actions = {
    SET_HEATMAP_INDICATOR: (state, action) => {
        const heatmap = { ...state.heatmap };
        const { indicator } = action;

        if (heatmap.indicator !== indicator) {
            heatmap.indicator = indicator;
        } else {
            heatmap.indicator = null;
        }

        return {
            ...state,
            heatmap: heatmap
        };
    },
    SET_HEATMAP_MEAN: (state, action) => {
        const heatmap = { ...state.heatmap };

        heatmap.mean = !heatmap.mean;

        return {
            ...state,
            heatmap: heatmap
        };
    },
    SELECT_TZHFL: (state, action) => {
        const { tzhfl } = state;
        const { target } = action;

        const selected = [...tzhfl.selected];

        let idx = selected.indexOf(target);
        if (idx !== -1) {
            selected.splice(idx, 1);
        } else {
            selected.push(target);
        }

        return {
            ...state,
            tzhfl: {
                selected: selected
            }
        };
    },
    OPEN_MENU: (state, action) => {
        const { open } = action;

        return {
            ...state,
            open: open
        };
    },
    SELECT_FEATURE: (state, action) => {
        const { firmSn, estateSn } = action;

        return {
            ...state,
            selected: {
                firmSn,
                estateSn
            }
        };
    }
};

const initialState = {
    open: false,
    heatmap: {
        mean: false,
        indicator: null
    },
    tzhfl: {
        selected: []
    },
    selected: {
        firmSn: null,
        estateSn: null
    }
};

const reducer = (state, action) => {
    const handler = actions[action.type];
    return handler ? handler(state, action) : state;
};

export default function useMenuReducer() {
    const [state, dispatch] = useReducer(reducer, initialState);

    return [state, dispatch];
}
