import React from "react";
import styled from 'styled-components';

import {
    useEffect,
    useRef,
    useState,
    useCallback,
} from "react";

// import { AsyncLoader } from '../util/panel';
import { loadIndicatorTrend } from './model';

import * as c3 from 'c3';




function generateChart(containerElemRef, indicator, data) {
    // const data = [
    //     ["x",],
    //     ["2018年",],
    //     ["2017年",],
    //     ["同比增长%",]
    // ];

    let xlabels = [
        "2018-Q4",
        "2018-Q3",
        "2018-Q2",
        "2018-Q1"
    ];
    
    let columns = [
        ["present", ...data.present,],
        ["last_year", ...data.last_year],
        ["growth", ...data.growth]
    ];


    let chart = c3.generate({
        bindto: containerElemRef.current,
        data: {
            names: {
                'present': '今年',
                'last_year': '去年',
                'growth': '增长率',
            },
            // x: 'x',
            columns: columns,
            type: 'bar',
            types: {
                'growth': 'line',
            },
            axes: {
                'present': 'y',
                'last_year': 'y',
                'growth': 'y2'
            },
        },
        axis: {
            x: {
                type: 'indexed',
                tick: {
                    // values: xlabels
                    format: (d) => {  return xlabels[d]; }
                }
            },
            y: {
                label: {
                    text: `${indicator}`,
                    position: 'outer-middle'
                }
            },
            y2: {
                show: true,
                label: {
                    text: '增长率%',
                    position: 'outer-middle'
                }
            }
        },
        grid: {
            y: {
                show: true
            }
        }        
    });

    return chart;
}

function IndicatorTrendSheet(props) {

    const { indicator } = props;

    const [records, setRecords] = useState([]);
    // const maxRatio = (records && records.length > 0) ? records[0].ratio : 1;

    const chartRef = useRef(null);
    // const load = useCallback(async () => {
    //     setRecords(await loadIndicatorTrend(indicator));
    // }, [indicator]);

    const [chart, setChart] = useState(null);

    useEffect(() => {
        // if (chart === null) {
        //     return;
        // }

        // console.log(1111, indicator);

        (async () => {

            let data = await loadIndicatorTrend(indicator);
            // console.log(1111222, data);


            const chart = generateChart(chartRef, indicator, data);

            // chart.load({
            //     columns: data,
            //     type: 'bar',
            //     types: {
            //         '同比增长%': 'line',
            //     },
            //     groups: [
            //         [data[1][0], data[2][0]]
            //     ]
            // });



        })();
    }, [indicator]);

    // console.log(2222, chart);


    useEffect(() => {
        // const chart = generateChart(chartRef);
        // setChart(chart);
    }, []);

    return (
        <Styled>
            {/* <AsyncLoader loader={load}> */}
            <div id="ddd111" ref={chartRef} />
            {/* </AsyncLoader> */}
        </Styled>
    );
}


const Styled = styled.div`

`;

export default IndicatorTrendSheet;
