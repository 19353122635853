import React from "react";
// import styled from "styled-components";

import DonutCount from "./SimpleDonutChart";

let colors = [
    "hsl(28, 100%, 53%)", // 未达供地条件
    "hsl(120, 57%, 40%)" // 鼓励类
];

export default function DlProvisionDonutChart(props) {
    const { data } = props;

    return (
        <DonutCount
            dataset={data}
            colors={colors}
            legend={{ fontSize: 18, offset: [-85, -25] }}
        />
    );
}

// const StyledDiv = styled.div`
//     width: 100%;
//     height: 100%;

//     .legend {
//         label {
//             color: #fff;
//         }

//     }

// `;
