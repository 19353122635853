import React from "react";
import {
    useEffect,
    useRef,
} from "react";
import styled from 'styled-components';

import * as d3 from "d3";

export default function Chart(props) {

    const elemRef = useRef(null);

    useEffect(() => {
        renderChart(elemRef.current, {
            width: 150,
            height: 24,
        });
    }, []);

    return (
        <StyledDiv className="heatmap-legend" ref={elemRef} />
    );
}

const StyledDiv = styled.div`
	height: 29px;
`;


function renderChart(elem, { width, height }) {

    // var width = 150;
    // var height = 36;

    //SVG container
    var svg = d3.select(elem)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(0, 0)`);

    //Needed for gradients			
    var defs = svg.append("defs");

    var coloursYGB = ['#00f', '#0ff', '#0f0', '#ff0', '#f00'];
    var colourRangeYGB = d3.range(0, 1, 1.0 / (coloursYGB.length - 1));
    colourRangeYGB.push(1);

    //Create color gradient
    var colorScaleYGB = d3.scaleLinear()
        .domain(colourRangeYGB)
        .range(coloursYGB)
        .interpolate(d3.interpolateHcl);


    //Calculate the gradient	
    defs.append("linearGradient")
        .attr("id", "gradient-ygb-colors")
        .attr("x1", "0%").attr("y1", "0%")
        .attr("x2", "100%").attr("y2", "0%")
        .selectAll("stop")
        .data(coloursYGB)
        .enter().append("stop")
        .attr("offset", function (d, i) { return i / (coloursYGB.length - 1); })
        .attr("stop-color", function (d) { return d; });

    let legendWidth = width,
        legendHeight = 10;

    //Color Legend container
    var legendsvg = svg.append("g")
        .attr("class", "legendWrapper")
        ;

    legendsvg.append("rect")  //Draw the Rectangle
        .attr("class", "legendRect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", legendWidth)
        .attr("height", legendHeight)
        .style("fill", "url(#gradient-ygb-colors)")
        ;


    let xScale = d3.scaleLinear() // Set scale for x-axis
        .range([10, legendWidth - 18])
        .domain([0, 100]);

    let formatPercent = d3.format("%");
    var xAxis = d3.axisBottom(xScale)
        // .orient("bottom")
        .ticks(2)
        // .tickFormat(formatPercent)
        // .scale(xScale);
        ;

    // let xScale = d3.scaleLinear() // Set scale for x-axis
    //     .range([10, legendWidth - 18])
    //     .domain([0, 1]);

    // let formatPercent = d3.format("%");
    // var xAxis = 
    //     d3.axisLeft(xScale)
    //     .ticks(2)
    //     .tickFormat(formatPercent)
    //     ;

    legendsvg.append("g")  //Set up X axis
        .attr("class", "axis")
        .attr("transform", `translate(0, ${legendHeight - 5})`)
        .style("font-size", `12px`)
        .call(xAxis);

    svg.select(".legendRect")
        ;
}