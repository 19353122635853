import React from "react";
import styled from "styled-components";

import { getUserProfile } from "./model";
import { useAsyncFunction } from "../util/async";

import Loading from "../util/panel/BouncingLoading";
import LoadingError from "../util/panel/LoadingError";

const LOGIN_PATH = "/login";

export default function Authenticated({ children }) {
    const {
        result: authenticated,
        error,
        processing,
        call: redo
    } = useAsyncFunction(async () => {
        await getUserProfile();
        return true;
    }, []);

    if (authenticated) {
        return children;
    } else {
        if (error && error.response) {
            if (error.response.status === 401) {
                forwardLogin();
                return;
            }
        }

        return (
            <StyledDiv className="authentication-fence">
                {!error && processing && <Loading />}
                {error && (
                    <LoadingError
                        error={castError(error)}
                        redo={() => redo()}
                    />
                )}
            </StyledDiv>
        );
    }
}

const StyledDiv = styled.div`
    width: 100%;
    min-height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

function forwardLogin() {
    const { pathname, search } = document.location;
    if (pathname.startsWith(LOGIN_PATH)) {
        throw new Error(`重复进入${LOGIN_PATH}`);
    }

    const params = new URLSearchParams();
    params.set("ref", pathname + search);

    document.location.href = `${LOGIN_PATH}?${params}`;
}

function castError(e) {
    if (e.message) {
        return {
            message: "认证错误，无法显示",
            detail: e.message
        };
    }

    return {
        message: "认证错误"
    };
}
