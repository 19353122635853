import React from "react";
import {
    useState,
    useCallback,
} from "react";

import styled from 'styled-components';

import { AsyncLoader } from '../util/panel';
import { getFeatureFirm } from './model';

import RadarChart from './RadarChart';
import FirmHeader from './FirmHeader';

function FeatureFirm(props) {

    const { firmSn } = props;
    const [data, setData] = useState(null);
    const [radarData, setRadarData] = useState(null);

    // 产值百分位数, 营业收入百分位数, 固投总额百分位数, 
    // 税收情况百分位数, 职工人数百分位数, 
    // 亩均产值百分位数, 亩均营业收入百分位数, 亩均固投总额百分位数, 
    // 亩均税收情况百分位数, 亩均职工人数百分位数


    const load = useCallback(async () => {
        const data = await getFeatureFirm(firmSn);
        setData(data);

        setRadarData([
            transformMetrics(data.metrics_last_year),
            transformMetrics(data.metrics),
        ]);

    }, [firmSn]);

    return (
        <StyledFeature className="feature">
            <AsyncLoader loader={load}>

                {data && <FirmHeader firm={data} />}

                <RadarChart width={150} 
                    data={radarData} 
                    legendLabels={['2017', '2018']} />

            </AsyncLoader>
        </StyledFeature>
    )
}

const StyledFeature = styled.div`
    margin: 10px 0;
    padding: 15px 10px;
    background-color: #fff;
    box-shadow: 2px 2px #ccc;

    min-height: 27em;

`;

export default FeatureFirm;

function transformMetrics(metrics) {

    const coalesce = (d, k) => {
        let value = d[k];
        return (value) ? value : 0;
    };

    return [
        {
            axis: "固投",
            value: coalesce(metrics, '固投总额百分位数')
        },
        {
            axis: "亩均产值",
            value: coalesce(metrics, '固投总额百分位数')
        },
        {
            axis: "职工人数",
            value: coalesce(metrics, '职工人数百分位数')
        },
        {
            axis: "亩均税收",
            value: coalesce(metrics, '亩均税收情况百分位数')
        },
        {
            axis: "亩均固投",
            value: coalesce(metrics, '亩均固投总额百分位数')
        },
        {
            axis: "产值",
            value: coalesce(metrics, '产值百分位数')
        },
        {
            axis: "营业收入",
            value: coalesce(metrics, '营业收入百分位数')
        },
        {
            axis: "税收",
            value: coalesce(metrics, '税收情况百分位数')
        },
    ];
}
