import React, {
    // useState, 
    // useEffect, 
    // useRef 
} from 'react'
import styled from 'styled-components';

import Ribbon from './Ribbon';

// import { useComponentSize} from './ComponentSize'


function CardPage(props) {
    // const [cardPageContext, setCardPageContext] = useState({
    //     viewportHeight: 0,
    // });

    const { title, extra } = props;

    // const elemRef = useRef(null);
    // const size = useComponentSize(elemRef);

    return (
        <div className={props.className}>
            <div className="card-header">
                {title && <Ribbon className="card-title">{title}</Ribbon>}
                <span className="card-header-middle"></span>
                {extra && <span className="card-header-extra">{extra}</span>}
            </div>

            {props.children}
        </div>
    )
}


export default styled(CardPage)`
    margin: 20px 30px 0px 30px;
    min-height: calc(100vh - 48px - 24px - 20px );

    & > .card-header {
        padding-top: 10px;
        display: flex;
        align-items: baseline;
    }

    & > .card-header > .card-title {
        text-align: center;
    }

    & > .card-header > .card-header-middle {
        flex: 1 1;
    }

    & > .card-header > .card-header-extra {
        padding-right: 2em;
        min-width: 10em;

    }

    background-color: #fff;
    box-shadow: 3px 4px 6px 0px;        
`;

