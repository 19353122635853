import React from "react";
import { useState, useEffect, useRef } from "react";
import Dimension from "./Dimension";

export default (Plot, config) => {
    if (config === undefined) {
        config = {};
    }

    return props => {
        const {
            className,
            style,
            data,
            onHighlighted,
            onDblClick,
            highlight
        } = props;

        const domNode = useRef(null);
        const [dimension, setDimension] = useState({});
        const [vizInitialized, setVizInitialized] = useState(false);
        const [plot, createPlot] = useState(null);

        useEffect(() => {
            const { width, height } = domNode.current.getBoundingClientRect();

            const { margin } = config;

            setDimension(
                new Dimension({
                    width,
                    height,
                    margin: margin
                })
            );

            createPlot(
                () =>
                    new Plot(domNode.current, {
                        ...config,
                        onHighlighted,
                        onDblClick
                    })
            );
        }, []);

        useEffect(() => {
            if (!plot || !dimension) {
                return;
            }

            if (data.length > 1 && dimension.width && !vizInitialized) {
                plot.init(data, dimension);
                setVizInitialized(() => true);
            }
            plot.init(data, dimension);
        }, [data, dimension]);

        useEffect(() => {
            if (highlight && plot.highlight) {
                plot.highlight(highlight);
            }
        }, [highlight]);

        return (
            <div
                ref={domNode}
                className={className}
                style={{ ...style, height: "100%" }}
            />
        );
    };
};
