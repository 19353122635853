import React from 'react';
import {
    useState,
    useEffect,
    useCallback,
    useRef,
} from 'react';

import {
    Empty
} from 'antd';

import clsx from 'clsx';

import styled from 'styled-components';

import Loading from './BouncingLoading';
import LoadingError from './LoadingError';

export default function AsyncLoader(props) {

    // loader, redo
    const onSize = props.onSize;
    const loader = props.loader;
    const redo = (props.redo !== undefined) ? props.redo : loader;

    const [loading, setLoading] = useState(true);
    const [isEmpty, setEmpty] = useState(false);
    const [error, setError] = useState(null);
    const elemRef = useRef(null);

    const runTask = useCallback((atask) => {

        (async () => {
            try {

                setError(null);
                setLoading(true);

                const empty = await atask();
                
                setEmpty(empty);

            } catch (e) {
                
                if (e.message) {
                    setError({
                        message: '因权限或服务受限，无法显示',
                        detail: e.message,
                    });
    
                } else {
                    setError({
                        message: '因权限或服务受限，无法显示',
                        detail: e.message,
                    });
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [setLoading, setError, setEmpty]);

    useEffect(() => {
        if (onSize) {
            const { clientHeight, clientWidth } = elemRef.current;
            onSize({
                width: clientWidth,
                height: clientHeight,
            });
        }

        runTask(loader);
    }, [onSize, runTask, loader]);

    let content;
    if (error) {
        content = (
            <LoadingError
                error={error}
                redo={() => { runTask(redo); }}
            />
        );
    } else if (loading) {
        content = (
            <Loading />
        );
    } else if (isEmpty) {
        content = (
            <Empty />
        );
    } else {
        return props.children;
    }

    return (
        <StyledDiv ref={elemRef}
            className={clsx(
                'async-loader',
                loading && ' loading',
                error && 'error-message',
            )}
        >{content}</StyledDiv>
    );
}

const StyledDiv = styled.div`
    width: 100%;
    min-height: 100%;

    &.loading, &.error-message {
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .ant-empty {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

`;

