import React from 'react';
import styled from 'styled-components';



import {
    Link,
} from "react-router-dom";


export default function Breadcrumb(props) {

    let routes = props.routes || [];

    const router = props.router || null;
    if (router !== null) {
        routes = router.getRouteStack();
    }

    // console.log('routes: ', routes);

    return (
        <StyledDiv className="breadcrumb" >
            {routes.map((route, index) => (
                <li key={index} >
                    <Link to={route}>
                        {route.state.title}
                    </Link>
                </li>
            ))}
        </StyledDiv>
    );
}


const menuBgColor = 'hsl(204, 70%, 53%)';
const activeMenuBgColor = 'hsl(212, 40%, 63%)';
const hoverBgColor = '#1abc9c';
const linkActiveBgColor = '#16a085';

const StyledDiv = styled.ul`
    list-style:none;
    display: flex;
    justify-content: flex-start;
    padding: 0px;
    margin: 0px;
    
    .icon {
        font-size: 14px;
    }
    
    li {
        a {
            color: #FFF;
            display: block;
            background: ${menuBgColor};
            text-decoration: none;
            position: relative;
            height: 40px;
            line-height:40px;
            padding: 0 10px 0 5px;
            text-align: center;
            margin-right: 23px;
        }

        &:first-child{
            a   {
                padding-left: 15px;
                border-radius: 4px 0 0 4px;
                
                &:before {
                    border:none;
                }
            }
        }
        
        :last-child {
            a {
                cursor: default;
                padding-right: 15px;
                border-radius: 0 1px 1px 0;
                background-color: ${activeMenuBgColor};

                :after{
                    border-left-color:${activeMenuBgColor};
                }
                
                :before {
                    border-color:${activeMenuBgColor};
                    border-left-color:transparent;
                }

                :hover {
                    background-color: ${activeMenuBgColor};
            
                    :before {
                        border-color: ${activeMenuBgColor};
                        border-left-color:transparent;
                    }

                    :after {
                        border-left-color: ${activeMenuBgColor};
                    }
                }
            }
        }

        a   {   
            :before, :after{
                content: "";
                position: absolute;
                top: 0;
                border: 0 solid ${menuBgColor};
                border-width: 20px 10px;
                width: 0;
                height: 0;
            }
            
            :before {
                left: -20px;
                border-left-color: transparent;
            }
            
            :after {
                left:100%;
                border-color:transparent;
                border-left-color:${menuBgColor};
            }
        
            :hover {
                background-color: ${hoverBgColor};
            
                :before{
                    border-color: ${hoverBgColor};
                    border-left-color:transparent;
                }
                :after{
                    border-left-color: ${hoverBgColor};
                }
            }

            :active {
                background-color: ${linkActiveBgColor};
                
                :before {
                    border-color:${linkActiveBgColor};
                    border-left-color:transparent;
                }
                
                :after  {
                    border-left-color:${linkActiveBgColor};
                }
            }


        }
    }
`;