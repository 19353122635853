export default class Dimension {
    constructor({ width, height, margin }) {
        this.width = width;
        this.height = height;

        this._margin = { top: 0, left: 0, bottom: 0, right: 0 };
        if (margin) {
            this._updateMargin(margin);
        }

        this._updateInnerDims();
    }

    set margin(margin) {
        if (margin) {
            this._updateMargin(margin);
            this._updateInnerDims();
        }
    }

    get margin() {
        return this._margin;
    }

    _updateMargin(margin) {
        if (margin) {
            const _margin = this._margin;
            const { top, left, bottom, right } = margin;
            if (top !== undefined) {
                _margin.top = top;
            }
            if (left !== undefined) {
                _margin.left = left;
            }

            if (bottom !== undefined) {
                _margin.bottom = bottom;
            }

            if (right !== undefined) {
                _margin.right = right;
            }
        }
    }

    _updateInnerDims = () => {
        this.innerHeight = this.height - (this.margin.top + this.margin.bottom);
        this.innerWidth = this.width - (this.margin.left + this.margin.right);
    };
}
