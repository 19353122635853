import React from "react";
import styled from "styled-components";

import ButtonList from "./ButtonList";

import clsx from "clsx";

export default function HomeDash(props) {
    const { state, dispatch } = props;

    let hidden = false;
    if (state && state.open) {
        hidden = true;
    } else if (state.selected.estateSn !== null) {
        hidden = true;
    } else if (state.heatmap.indicator !== null) {
        hidden = true;
    } else if (state.tzhfl.selected.length > 0) {
        hidden = true;
    }

    return (
        <Styled className={clsx("navigation glass", hidden && "hidden")}>
            <div className="row">
                <ButtonList />
            </div>
        </Styled>
    );
}

const Styled = styled.div`
    --dash-height: 9em;
    --dash-width: 43em;

    &.hidden {
        display: none;
    }

    display: flex;
    flex-direction: column;

    width: var(--dash-width);
    height: var(--dash-height);
    padding: 20px;
    top: calc((100% - var(--dash-height)) / 2);
    left: calc((100% - var(--dash-width)) / 2);
    background-color: #fffffff0;
    position: absolute;

    pointer-events: all;
    user-select: none;

    &.glass {
        position: relative;
        padding: 15px 25px;
        /* background-color: hsl(201, 50%, 37%); */

        text-decoration: none;
        color: #fff;
        /* font-size: 1.8em; */
        font-family: sans-serif;
        font-weight: 100;

        background-image: linear-gradient(
            hsla(224, 26%, 28%, 1),
            hsla(224, 26%, 28%, 0.5)
        );
        box-shadow: 0px 0px 3px hsla(224, 26%, 28%, 1),
            3px 3px 3px hsla(224, 26%, 28%, 0.8);
        border-radius: 10px;

        ::after {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: calc(100% - 0px);
            height: 50%;
            background: linear-gradient(
                rgba(255, 255, 255, 0.7),
                rgba(255, 255, 255, 0)
            );
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            pointer-events: none;
        }
    }

    .menu-item {
        flex: 0 0 13%;
        border-style: none;

        .title {
            color: #fff;
            margin-top: 5px;
            font-size: 1em;
            font-weight: bold;
            letter-spacing: 0.1em;
        }
    }

    .header {
        font-size: 1.1em;
        padding-left: 2em;
        user-select: none;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`;
