import React from "react";
import styled from "styled-components";

import { useState } from "react";
import Button from "@material-ui/core/Button";
import clsx from "clsx";

import { useAsyncFunction } from "../util/async";
import ActionCurtain from "../util/ActionCurtain";
import NeicSunburstChart from "./NeicSunburst";
import Page from "./Page";

import axios from "axios";

const indicators = ["固投总额", "产值", "营业收入", "税收情况", "职工人数"];

export default function NeicPage(props) {
    const [indicator, setIndicator] = useState("产值");
    const loader = useAsyncFunction(async () => {
        const resp = await axios.get(`/api/stat/neic/${indicator}`);
        return resp.data;
    }, [indicator]);

    return (
        <StyledPage
            title={
                <>
                    <span>产业经济结构</span>
                    <div className="option-list">
                        {indicators.map((ind, idx) => (
                            <Button
                                className={clsx(
                                    "option",
                                    indicator === ind && "checked"
                                )}
                                key={idx}
                                onClick={() => setIndicator(ind)}
                            >
                                {ind}
                            </Button>
                        ))}
                    </div>
                </>
            }
        >
            <ActionCurtain action={loader}>
                <div className="chart">
                    <NeicSunburstChart data={loader.result} />
                </div>
            </ActionCurtain>
        </StyledPage>
    );
}

const StyledPage = styled(Page)`
    font-size: calc(6px + 2vmin);
    /* width: calc(100vw - 25rem - 1rem); */
    width: fit-content;
    height: fit-content;

    /* left: calc(2rem / 2 ); */

    /* right: 2em; */
    margin-left: 30px;

    z-index: 1;

    right: unset;

    /* display: flex; */
    /* flex-direction: column; */

    .chart {
        margin-top: 24px;

        width: calc(100vw - 5rem);
        height: calc(100vh - 5rem);
    }

    .page-header > .title {
        display: flex;
    }

    .option-list {
        margin-left: 1em;
        display: flex;
        align-items: flex-end;
        /* flex-wrap: wrap; */
        /* justify-content: space-between; */
        /* font-size: 0.6em; */
        width: fit-content;
        color: #f8f8f8;

        > .option {
            padding: 2px 2px;
            font-size: 0.6em;
            min-width: 6em;
            flex: 0 0 18%;
            color: #f8f8f8;

            border: 1px solid hsla(0, 0%, 100%, 0);

            height: fit-content;

            &.checked {
                color: #fff;
                font-weight: bold;
                border: 1px solid rgba(223, 247, 20, 0.5);
            }
        }
    }
`;
