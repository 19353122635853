import React, {
    useCallback,
} from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

const options = ['鼓励类', '限制类', '淘汰类'];

export default function SelectTzhfl(props) {

    const { tzhfl, dispatch } = props;


    const handleClickOption = useCallback((option) => {
        return () => {
            dispatch({
                type: 'SELECT_TZHFL',
                target: option,
            });
        };
    }, [dispatch]);

    const selected = {};
    for (let option of tzhfl.selected) {
        selected[option] = true;
    }

    return (
        <StyledHeatSelection>
            <div className="menu-section">
                <div className="title">产业调整分类</div>
            </div>
            <div className="option-list">
                {options.map((option, idx) => (
                    <Button
                        className={clsx("option",
                            selected[option] && 'checked')}
                        key={idx}
                        onClick={handleClickOption(option)}>
                        {option}
                    </Button>
                ))}
                <div />
                <div />
            </div>

            {/* <div className="row">
                <Link to={`/map/tzhfl`}>产业调整目录</Link>

            </div> */}
        </StyledHeatSelection>
    );
}



const StyledHeatSelection = styled.div`
    margin-bottom: 20px;

    > h3 {
        font-size: 0.8em;
        font-weight: bold;
        color: #999;
    }

    > .row > a {
        padding: 2px 4px;
        font-size: 0.6rem;
        border-radius: 0;
        flex: 0 0 20%;
        text-align: center;
        color: #888;
    }

    .menu-section {
        display: flex;
        > .title {
            flex: 1 1;
        }

        > .extra {
            .MuiCheckbox-root {
                padding: 0;
                .MuiSvgIcon-root {
                    width: 0.8em;
                    height: 0.8em;
                    line-height: 1.5em;
                    margin-right: 3px;
                }
            }            
        }
    }

    .option-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > .option {
            padding: 2px 2px;
            font-size: 0.9em;
            min-width: unset;
            flex: 0 0 18%;

            border: 1px solid hsla(0, 0%, 100%, 0);

            &.checked {
                color: hsl(225, 78%, 40%);
                border: 1px solid hsla(225, 78%, 40%, 0.5);
            }
        }

    }
`
