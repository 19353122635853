import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import * as d3 from "d3";

import axios from "axios";
import { useAsyncFunction } from "../util/async";
import ActionCurtain from "../util/ActionCurtain";
import useElementSize from "../util/useElementSize";

export default function GutouLineChart(props) {
    const loader = useAsyncFunction(async () => {
        const response = await axios.get(`/api/dashboard/summary/neic`);
        return response.data.slice(0, 25);
    }, []);

    if (!loader.result) {
        return <ActionCurtain action={loader} />;
    }

    return <BarChart data={loader.result} />;
}

function BarChart({ data }) {
    const containerRef = useRef(null);
    // const contentSize = useElementSize(containerRef);

    // let contentWidth = contentSize.width > 5 ? contentSize.width - 5 : 0;
    // let contentHeight = contentSize.height > 5 ? contentSize.height - 5 : 0;

    useEffect(() => {
        if (
            containerRef.current === null  // ||
            // contentWidth === 0 ||
            // contentHeight === 0
        ) {
            return;
        }

        const settings = {
            width: 570,
            height: 300,
            // width: contentWidth,
            // height: contentHeight,
            margin: {
                top: 5,
                right: 5,
                bottom: 5,
                left: 60
            }
        };

        render(containerRef.current, data, settings);
    }, [data]);

    return <Styled  ref={containerRef} ></Styled>;
}

const Styled = styled.div`
    width: 100%;
    height: 100%;
    /* user-select: none; */

    > svg {
        width: 100%;
        height: 100%;
    }

    .bar {
        &.y1 {
            fill: hsl(24, 100%, 50%, 1);
        }
        &.y2 {
            fill: hsl(43, 91%, 27%);
        }
    }

    .title {
        fill: hsla(0, 0%, 80%, 1);
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.12em;
    }

    .label {
        fill: hsla(0, 0%, 93%, 1);
        font-size: 10px;
    }

    .axis {
        font-weight: bold;
        font-size: 12px;

        path {
            stroke: #aaa;
            stroke-width: 2px;
        }

        .tick line {
            stroke: #aaa;
            stroke-width: 1px;
        }

        &.x text {
            fill: hsla(0, 0%, 93%, 1);
        }

        &.y1 text,
        &.y2 text {
            fill: hsla(0, 0%, 93%, 1);
        }
    }
`;

// def to_month_no(year, month):
//     return (year - 2000)*12 + month - 1

// def from_month_no(month_no):
//     year = 2000 + month_no // 12
//     month = month_no % 12 + 1
//     return year, month

// function formatMonthNo(month_no) {
//     const year = 2000 + Math.trunc(month_no / 12);
//     const month = (month_no % 12) + 1;
//     return `${year}.${month}`;
// }

function render(chartEl, data, { width, height, margin }) {
    let innerWidth = width - margin.left - margin.right;
    let innerHeight = height - margin.top - margin.bottom;
    let horizontalY = margin.top + innerHeight / 2;

    d3.select(chartEl)
        .select("svg")
        .remove();

    const svg = d3
        .select(chartEl)
        .append("svg")
        .attr("viewBox", `0 0 ${width} ${height}`)
        .append("g")
        ;
    // .attr("transform", `translate(${marginLeft}, ${marginTop})`)
    // let width = 700;
    // let height = 500;
    // let margin = { top: 20, right: 0, bottom: 30, left: 40 };

    let x = d3
        .scaleBand()
        .domain(data.map(d => d.name))
        .range([margin.left, width - margin.right])
        .padding(0.2);

    let y1 = d3
        .scaleLinear()
        .domain([0, d3.max(data, d => d.value1)])
        .nice()
        .range([horizontalY, margin.top]);

    let y2 = d3
        .scaleLinear()
        .domain([0, d3.max(data, d => d.value2)])
        .nice()
        .range([horizontalY, height - margin.bottom]);

    let y1Axis = g =>
        g
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(d3.axisLeft(y1).ticks(3))
            .call(g => g.select(".domain").remove());

    let y2Axis = g =>
        g
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(d3.axisLeft(y2).ticks(3))
            .call(g => g.select(".domain").remove());

    svg.append("g")
        .attr("fill", "red")
        .selectAll("darkrect")
        .data(data)
        .join("rect")
        .attr("x", d => x(d.name))
        .attr("y", d => y1(d.value1))
        .attr("height", d => y1(0) - y1(d.value1))
        .attr("width", x.bandwidth());

    svg.append("g")
        .attr("class", "y2 bar")
        .selectAll("darkrect")
        .data(data)
        .join("rect")
        .attr("x", d => x(d.name))
        .attr("y", d => horizontalY)
        .attr("height", d => y2(d.value2) - y2(0))
        .attr("width", x.bandwidth());

    svg.append("g")
        .attr("class", "label")
        .attr("text-anchor", "start")
        .selectAll("text")
        .data(data)
        .enter()
        .append("text")
        .attr("transform", d => {
            const xx = x(d.name) + (x.bandwidth() - 8) / 2;
            return `translate(${xx} , ${horizontalY})`;
        })
        .text(d => d.name);

    // const xAxis = d3.axisBottom(x).tickSizeOuter(0).tickFormat("");
    // svg.append("g")
    //     .attr("transform", `translate(0,${height - margin.bottom})`)
    //     .attr("class", "x axis")
    //     .call(xAxis);

    svg.append("g")
        .attr("class", "y1 axis")
        .call(y1Axis);

    svg.append("g")
        .attr("class", "y2 axis")
        .call(y2Axis);

    svg.append("g")
        .attr("class", "title y1")
        .append("text")
        .attr("x", width - margin.right - 20)
        .attr("y", 20)
        .style("text-anchor", "start")
        .text("行业产值");

    svg.append("g")
        .attr("class", "title y1")
        .append("text")
        .attr("x", 10)
        .attr("y", height - 60)
        .style("text-anchor", "start")
        .text("企业数");

    svg.selectAll(".label text").call(verticalText);
    svg.selectAll(".title text").call(verticalText);

    function verticalText(text) {
        text.each(function() {
            let text = d3.select(this);
            let label = text.text();

            let x = text.attr("x") || 0;

            text.text(null);
            for (let lineNo = 0; lineNo < label.length; lineNo++) {
                text.append("tspan")
                    .attr("x", x)
                    .attr("dy", `1.12em`)
                    .text(label[lineNo]);
                if (lineNo === 9) {
                    break;
                }
            }
        });
    }
}
