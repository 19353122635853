import React from "react";
import styled from 'styled-components';

import {
    useState,
    useCallback,
} from "react";


import Button from '@material-ui/core/Button';
// import ButtonGroup from '@material-ui/core/ButtonGroup';

import Page from './Page';

import { loadIndicatorRank } from './model';
import IndicatorRankSheet from './IndicatorRankSheet';

const tabs = [
    '固投总额', '产值', '营业收入', '税收情况', '职工人数',
    '亩均固投总额', '亩均产值', '亩均营业收入', '亩均税收情况', '亩均职工人数',
];




function RankPage(props) {

    const [records, setRecords] = useState(null);
    const [tabIdx, setTabIdx] = useState(0);

    const handleTabChange = useCallback((event, nextTabIdx) => {

        setTabIdx(nextTabIdx);
    });

    const load = useCallback(async () => {
        setRecords(await loadIndicatorRank(tabs[tabIdx]));
    }, [tabIdx]);

    return (
        <StyledPage className="page" title="企业排名" >
            <div className="page-body">
                <div className="row">
                    <Button variant="contained" size="small"
                        onClick={() => setTabIdx(0)}>{tabs[0]}</Button>
                    <Button variant="contained" size="small"
                        onClick={() => setTabIdx(1)}>{tabs[1]}</Button>
                    <Button variant="contained" size="small"
                        onClick={() => setTabIdx(2)}>{tabs[2]}</Button>
                    <Button variant="contained" size="small"
                        onClick={() => setTabIdx(3)}>{tabs[3]}</Button>
                    <Button variant="contained" size="small"
                        onClick={() => setTabIdx(4)}>{tabs[4]}</Button>

                    <Button variant="contained" size="small"
                        onClick={() => setTabIdx(5)}>{tabs[5]}</Button>
                    <Button variant="contained" size="small"
                        onClick={() => setTabIdx(6)}>{tabs[6]}</Button>
                    <Button variant="contained" size="small"
                        onClick={() => setTabIdx(7)}>{tabs[7]}</Button>
                    <Button variant="contained" size="small"
                        onClick={() => setTabIdx(8)}>{tabs[8]}</Button>
                    <Button variant="contained" size="small"
                        onClick={() => setTabIdx(9)}>{tabs[9]}</Button>
                </div>

                <IndicatorRankSheet indicator={tabs[tabIdx]} />

            </div>
        </StyledPage>
    );
}

export default RankPage;

const StyledPage = styled(Page)`
    width: 50%;
    
    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > button {
            flex: 0 0 18%;
            border-radius: 0;

            margin: 0 0 10px 0;
        }
    }
`;

