import React from "react";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import * as d3 from "d3";

import useElementSize from "../util/useElementSize";

export default function SimpleDonutChart({ dataset, colors, legend }) {
    const containerRef = useRef(null);
    const contentSize = useElementSize(containerRef);
    const elemRef = useRef(null);

    useEffect(() => {
        if (elemRef.current === null) {
            return;
        }

        renderDonutChart(elemRef.current, dataset, colors, legend);
    }, [dataset, colors, legend]);

    let contentWidth = contentSize.width > 5 ? contentSize.width - 5 : 0;
    let contentHeight = contentSize.height > 5 ? contentSize.height - 5 : 0;

    return (
        <Styled ref={containerRef} className="donutchart">
            <svg
                width={contentWidth}
                height={contentHeight}
                ref={elemRef}
                preserveAspectRatio="xMidYMid meet"
                viewBox={`0 0 300 300 `}
            />
        </Styled>
    );
}

const Styled = styled.div`
    height: 100%;
    width: 100%;

    .percent {
        fill: #fff;
        font-weight: bold;
    }

    .legend text {
        fill: #eee;
        font-size: 24px;
    }
`;

function renderDonutChart(chartEl, dataset, colors, legendParams) {
    let pie = d3
        .pie()
        .value(d => d.value)
        .sort(null)
        .padAngle(0.03);

    const w = 300,
        h = 300;
    let outerRadius = w / 2;
    let innerRadius = 100;

    let svg = d3
        .select(chartEl)
        .append("svg")
        .attr("width", w)
        .attr("height", h)
        .attr("class", "shadow")
        .append("g")
        .attr("transform", `translate(${w / 2}, ${h / 2})`);

    if (!colors) {
        colors = d3.schemeCategory10;
    }
    let color = d3.scaleOrdinal(colors);

    let arc = d3
        .arc()
        .outerRadius(outerRadius)
        .innerRadius(innerRadius);

    let path = svg
        .selectAll("path")
        .data(pie(dataset))
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", (d, i) => color(d.data.name));

    path.transition()
        .duration(500)
        .attrTween("d", function(d) {
            let interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
            return function(t) {
                return arc(interpolate(t));
            };
        });

    let renderData = function() {
        const formatNumeric = d3.format(".0f");

        svg.selectAll("text")
            .data(pie(dataset))
            .enter()
            .append("text")
            .transition()
            .duration(200)
            .attr("transform", function(d) {
                return "translate(" + arc.centroid(d) + ")";
            })
            .attr("dy", ".4em")
            .attr("text-anchor", "middle")
            .attr("class", "percent")
            .text(d => (d.data.value > 0 ? formatNumeric(d.data.value) : ""));

        let legendRectSize = 24;
        let legendSpacing = 1.25 * legendRectSize - legendRectSize;
        let legendHeight = legendRectSize + legendSpacing;
        let legendOffsetX = -45;
        let legendOffsetY = -60;

        if (legendParams) {
            const { fontSize, offset } = legendParams;

            if (fontSize) {
                legendRectSize = fontSize;
            }
            // if (spacing) {
            //     legendSpacing = spacing;
            //     legendHeight = legendRectSize + legendSpacing;
            // }
            if (offset) {
                legendOffsetX = offset[0];
                legendOffsetY = offset[1];
            }
        }

        let legend = svg
            .selectAll(".legend")
            .data(color.domain())
            .enter()
            .append("g")
            .attr("class", "legend")
            .attr(
                "transform",
                (d, i) =>
                    `translate(${legendOffsetX}, ${i * legendHeight +
                        legendOffsetY} )`
            );

        legend
            .append("rect")
            .attr("width", legendRectSize)
            .attr("height", legendRectSize)
            // .attr('rx', 20)
            // .attr('ry', 20)
            .style("fill", color)
            .style("stroke", color);

        legend
            .append("text")
            .attr("x", 30)
            .attr("y", 0.8 * legendRectSize)
            .style("font-size", `${legendRectSize}px`)
            .text(d => d);
    };

    renderData();

    // setTimeout(restOfTheData, 100);
}
