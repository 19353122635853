import React from "react";
import styled from "styled-components";
// import axios from "axios";

import GrowthScatterChart from "./GrowthScatter";

// import DevlopQuantileChart from "./GrowthScatter/DevlopQuantileChart";
import Page from "./Page";

// import { useAsyncFunction } from "../util/async";
// import ActionCurtain from "../util/ActionCurtain";

function DevelopDistPage({onFirmSelected, props}) {
    // const loader = useAsyncFunction(async () => {
    //     const resp = await axios.get(`/api/geomap/develop`);
    //     return resp.data;
    // }, []);

    return (
        <StyledPage title="企业发展排位">
            <GrowthScatterChart onFirmSelected={onFirmSelected} />
        </StyledPage>
    );
}

const StyledPage = styled(Page)`
    width: fit-content;
    height: fit-content;

    margin-right: 5px;
    z-index: 1;
    
    > .action-fence {
        flex: 1 1;
    }
`;

export default DevelopDistPage;
