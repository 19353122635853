import React from "react";
// import {
//     useState,
//     useCallback,
// } from "react";

import styled from 'styled-components';
import Statistic from '../common/Statistic';
import HierarchicalTag from './HierarchicalTag';

function FirmHeader(props) {
    const { firm } = props;

    let tzhflClassName = 'tzhfl ant-tag';
    if (firm.tzhfl==='鼓励类') {
        tzhflClassName += ' success';
    } else if (firm.tzhfl==='限制类') {
        tzhflClassName += ' warning';
    } else if (firm.tzhfl==='淘汰类') {
        tzhflClassName += ' error';
    }

    return (
        <StyledDiv>
            <div className="title">
                <label>{firm.firm_name}</label>
                <div className="extra">
                    {firm.tzhfl &&
                        <span className={tzhflClassName} >{firm.tzhfl} </span>
                    }
                </div>
            </div>

            <HierarchicalTag label={[firm.neic2, firm.neic3, firm.neic4]} />

            <div className="metrics">
                <Statistic title="固投总额(万元)"
                    value={firm.metrics['固投总额']}
                    pastValue={firm.metrics_last_year['固投总额']}
                    precision="2" />

                <Statistic title="产值(万元)"
                    value={firm.metrics['产值']}
                    pastValue={firm.metrics_last_year['产值']}
                    precision="2" />

                <Statistic title="税收情况(万元)"
                    value={firm.metrics['税收情况']}
                    pastValue={firm.metrics_last_year['税收情况']}
                    precision="2" />

                <Statistic title="职工人数"
                    value={firm.metrics['职工人数']}
                    pastValue={firm.metrics_last_year['职工人数']}
                    precision="2" />

            </div>
        </StyledDiv>
    );
}

const StyledDiv = styled.div`
    .metrics {
        display: flex;
        flex: 1 30%;
        justify-content: space-around;
        align-items: stretch;
        padding: 10px 0;
        font-size: 1.1em;
    }

    > .title {
        display: flex;
        align-items: center;

        > label {
            flex: 1 0;
            font-size: 1.2em;
            font-weight: bolder;
        }

        > .extra {
            min-width: fit-content;

            font-size: 0.85em;
            font-weight: bold;
        
            > .tzhfl.ant-tag {
                color: #fff;
                padding: 1px 5px;
                border-radius: 3px;  
                background-color: hsl(102, 83%, 26%);
            
                &.warning {
                    background-color: hsl(20, 82%, 50%);
                }

                &.error {
                    background-color: hsl(0, 0%, 40%);    
                }
            }        
        }
    }

`;

export default FirmHeader;