import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;        

    overflow-x: hidden;
    overflow-y: hidden;

    height: 100%;
}

body > #root {
    height: 100%;
}

.scroller {
    &::-webkit-scrollbar {
        width: 8px;
        display: none;
        position: absolute;
        right: -2em;
        margin: 10px 10px;
    }

    &:hover::-webkit-scrollbar {
        display: block;
    }

    &::-webkit-scrollbar-track {
        /* background : #FF5A93; */
        background-color: hsl(201, 55%, 59%);
        border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb {
        background : rgba(255,255,255,0.5);
        border-radius: 3px;
        box-shadow:  0 0 2px rgba(0, 0, 0, 0.5);
    } 
}

.page1 {
    display: flex;
    flex-direction: column;

    /* position: relative; */


    background-color: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 
                0px 1px 1px 0px rgba(0,0,0,0.14), 
                0px 2px 1px -1px rgba(0,0,0,0.12);
    
    border-radius: 4px;

    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;

    > .page-body {
        flex: auto;
        /* height: 0;  */
        /* page body 因该占用剩余的空间，为了使得子代元素height： 100%  发挥作用，需要
         * 设置height: 0
         *https://github.com/philipwalton/flexbugs/issues/197
         */
    } 
}

button.link {
    border-style: none;
    box-shadow:none;
    user-select: none;
    touch-action: manipulation;
    font-size: inherit;
    color: rgba(0, 0, 0, 0.65);
    background-color: transparent;
    cursor: pointer;

    :hover {
        text-shadow: 0px 0px 1px #c1c1c1;
    }

    > label {
        cursor: inherit;
    }

    &.primary {
        color: hsl(207, 100%, 63%)
    }
}


.ant-btn.link-btn {
    font-weight: 400;
    border-style: none;
    box-shadow:none;
    user-select: none;
    touch-action: manipulation;
    /* height: inherit; */
    /* padding: 0 15px; */
    font-size: inherit;
    /* border-radius: 4px; */
    color: rgba(0, 0, 0, 0.65);
    /* background-color: #fff; */
    background-color: transparent;
    /* border-color: #d9d9d9; */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}



`;
