import { keyframes } from 'styled-components';


export const fadeInDown = keyframes`
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
  
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;

export const fadeInRight = keyframes`
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
`;

export const rotateInDownRight = keyframes`
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;


export const fadeOutLeft = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }
`;

export const bounceInUp = keyframes`
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

export const bounceOutUp = keyframes`
    20% {
        transform: translate3d(0, -10px, 0);
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, 20px, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(0, -2000px, 0);
    }
`;

export const zoomOut = keyframes`
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
`;

export const zoomOutRight = keyframes`
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(0.1) translate3d(2000px, 0, 0);
    transform-origin: right center;
  }
`;
