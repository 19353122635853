import React from "react";
import styled from "styled-components";

// import iconLoadingError from "./loading-error.svg";

import ErrorIcon from "./ErrorIcon";

export default function LoadingError(props) {
    const { error, redo } = props;

    return (
        <Error className="error">
            <div className="info">
                <div className="icon">
                    <ErrorIcon />
                </div>

                <h3 className="title">
                    <span>{error.message}</span>
                </h3>

                <button className="btn" onClick={redo}>
                    再试一次
                </button>
                {/* {error.detail && <div className="detail">{error.detail}</div>} */}
            </div>
        </Error>
    );
}

const Error = styled.div`
    height: 100%;
    display: flex;
    flex-direction: flex;
    justify-items: center;
    align-items: center;

    .info {
        display: grid;
        grid-template-rows: 1fr auto auto;
        grid-template-columns: 0.3fr 1fr;

        .icon {
            grid-row: 1 / span 3;
            grid-column: 1;

            padding: 10px 20px;

            height: 7em;
        }

        .title {
            grid-row: 1;
            grid-column: 2;
            font-size: 1.8em;
            line-height: 2em;
            margin: 0;

            align-self: center;

            color: hsl(0, 98%, 33%);
        }

        .btn {
            grid-row: 2;
            grid-column: 2;

            background-color: transparent;
            border: none;
            padding: inherit;
            cursor: pointer;
            color: blue;

            text-align: right;

            letter-spacing: 0.15em;
            font-weight: bold;

            :focus {
                outline: none;
            }
        }
    }

    .detail {
        margin: 20px 0;
        text-align: center;
        width: 100%;
    }
`;
