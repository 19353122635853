import React from "react";

import { ReactComponent as DashboardIcon } from "./icons/dashboard.svg";
import { ReactComponent as RankIcon } from "./icons/rank.svg";
import { ReactComponent as TrendIcon } from "./icons/trend.svg";
import { ReactComponent as DevdistIcon } from "./icons/scatter.svg";
import { ReactComponent as SunburstIcon } from "./icons/sunburst.svg";
import { ReactComponent as MapIcon } from "./icons/map.svg";

import IconButton from "./IconButton";

export default function ButtonList(props) {
    return (
        <>
            <IconButton
                to="/dashboard"
                icon={<DashboardIcon />}
                title="仪表盘"
            />
            <IconButton to="/map/rank" icon={<RankIcon />} title="企业排名" />
            <IconButton to="/map/trend" icon={<TrendIcon />} title="趋势分析" />
            <IconButton
                to="/map/neic"
                icon={<SunburstIcon />}
                title="产业结构"
            />
            <IconButton
                to="/map/devdist"
                icon={<DevdistIcon />}
                title="发展排位"
            />
            <IconButton to="/map" icon={<MapIcon />} title="主图" />
        </>
    );
}
