import "ol/ol.css";
import "c3/c3.css";

import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import GlobalStyle from "./style";
import App from "./App";
// import App from "./TestBed";

import { ConfigProvider } from "antd";
import zh_CN from "antd/lib/locale-provider/zh_CN";
import "moment/locale/zh-cn";

ReactDOM.render(
    <>
        <GlobalStyle />
        <ConfigProvider locale={zh_CN}>
            <App />
        </ConfigProvider>
    </>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
