import React from 'react';
import {
    useState,
    useRef,
    useCallback,
    useEffect,
} from 'react';

// import clsx from 'clsx';
import styled from 'styled-components';

import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';

import {
    Icon,
} from 'antd';

// import errorIcon from "./error.svg";


export default function AsyncButton({
    className,
    onAction,
    okText,
    confirmMessage,
    stopPropagation,
    onError,
    disabled,
    children,
    confirmComponent,
    // ...props
}) {
    const [busying, setBusying] = useState(false);
    const buttonElemRef = useRef(null);

    const [confirm, setConfirm] = useState(null);
    const [errorConfirm, setErrorConfirm] = useState(null);

    let ConfirmComponent;
    if (confirmComponent === undefined) {
        ConfirmComponent = Confirm;
    } else {
        ConfirmComponent = confirmComponent;
    }
    // ConfirmComponent = Confirm;

    const handleAction = useCallback((answer) => {
        setTimeout(() => {
            (async () => {
                try {
                    setBusying(true);
                    await onAction(answer);
                } catch (error) {
                    if (onError) {
                        onError(error);
                    } else {
                        let message;
                        if (error.message) {
                            message = error.message;
                        } else {
                            message = error.toString();
                            if (!message) {
                                message = '发生错误，请重试';
                            }
                        }
                        console.error(error);
                        setErrorConfirm({
                            message: message,
                        });
                    }
                } finally {
                    setBusying(false);
                }
            })();
        }, 0);
    }, [onAction, onError]);

    const handleConfirmSubmit = useCallback((answer) => {

        if (answer) {
            handleAction(answer);
        }
        setConfirm(null);

    }, [handleAction]);

    const handleErrorSubmit = useCallback((answer) => {
        setErrorConfirm(null);
    }, []);

    const handleClick = useCallback((event) => {

        if (stopPropagation) {
            event.stopPropagation();
        }

        if (onAction === undefined) {
            return;
        }

        if (typeof onAction !== 'function' || onAction.constructor.name !== 'AsyncFunction') {
            console.warn('onAction should be a async function, instead of ', onAction);
            return;
        }

        if (confirmMessage && confirmComponent === undefined) {
            setConfirm({
                content: confirmMessage,
            });

            return;
        }

        if (confirmComponent) {
            setConfirm(confirmMessage || {});

            return;
        }

        setTimeout(handleAction, 0);

    }, [handleAction, onAction, confirmMessage, confirmComponent, stopPropagation]);

    return (
        <StyledDiv className="async-button">
            <Button className={className} disabled={disabled || busying}
                ref={buttonElemRef}
                onClick={handleClick}>
                {children}
                {busying &&
                    <div className="in-process">
                        <Icon type="loading" />
                    </div>
                }
            </Button>
            {confirm && <Popup anchorEl={buttonElemRef.current}
                component={ConfirmComponent}
                message={confirm}
                onSubmit={handleConfirmSubmit}
                okText={okText}
            />}
            {errorConfirm && <Popup anchorEl={buttonElemRef.current}
                component={ErrorConfirm}
                message={errorConfirm}
                onSubmit={handleErrorSubmit}
            />}
        </StyledDiv>
    );
}


function Popup({ anchorEl, message, onSubmit, okText, component, ...props }) {

    const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);

    useEffect(() => {
        if (message) {
            setPopoverAnchorEl(anchorEl);
        }
    }, [message, anchorEl]);

    const handleSubmit = useCallback((answer) => {
        setPopoverAnchorEl(null);
        setTimeout(() => {
            if (onSubmit) {
                onSubmit(answer);
            }
        }, 0);
    }, [onSubmit]);

    const handlePopoverClose = useCallback(() => {
        handleSubmit(false);
    }, [handleSubmit]);

    const Component = component;

    return (
        <Popover
            open={popoverAnchorEl !== null}
            anchorEl={popoverAnchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}>
            {<Component message={message}
                onSubmit={handleSubmit}
                okText={okText}
                {...props} />}
        </Popover>
    )
}

function Confirm({ message, okText, onSubmit }) {

    const onOk = useCallback(() => {
        if (onSubmit) {
            onSubmit(true);
        }
    }, [onSubmit]);

    const onCacnel = useCallback(() => {
        if (onSubmit) {
            onSubmit(false);
        }
    }, [onSubmit]);

    return (
        <StyledConfirm>
            <div className="content">
                {message.content}
            </div>

            <div className="buttons">
                <Button className="cancel" size="small"
                    variant="contained"
                    onClick={onCacnel}>取消</Button>
                <Button className="ok"
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={onOk}>{okText || '确定'}</Button>
            </div>
        </StyledConfirm>
    );
}


function ErrorConfirm({ message, onSubmit }) {

    const onOk = useCallback(() => {
        if (onSubmit) {
            onSubmit(true);
        }
    }, [onSubmit]);

    return (
        <StyledConfirm className="error">
            <div className="content">
                {message.message}
            </div>
            <div className="buttons">
                <Button className="ok"
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={onOk}>确定</Button>
            </div>
        </StyledConfirm>
    );
}

const StyledConfirm = styled.div`
    padding: 10px 10px 10px 5px;
    min-width: 12rem;
    min-height: 3rem;

    display: flex;
    flex-direction: column;

    &.error {
        border: hsla(0, 91%, 48%, 0.63);
        border-style: solid;
        border-width: 2px;
        border-radius: 4px;

        > .content {
            font-size: 1.2em;
            color: hsl(0, 81%, 45%);
        }
    }

    .MuiButton-containedPrimary {
        background-color: hsl(204, 70%, 53%);
    }

    .MuiButton-containedPrimary:hover {
        background-color: hsl(204, 70%, 48%);
    }

    .content {
        padding: 10px 10px;
    }

    .buttons {
        display: flex;
        align-self: flex-end;
        > button {
            font-size: 0.8rem;
            padding: 0px 0px;
            min-width: 3.5rem;
            margin: 5px 5px 0 5px;
        }
    }
`;

const StyledDiv = styled.div`
    width: fit-content;
    height: fit-content;

    
    button  .in-process {
        position: absolute;
        font-size: 1.2em;
        color: #3fb6fd;
    }

`;

