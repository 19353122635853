import React, {
  useState,
  useEffect,
  useRef,
  // useImperativeHandle,
  // forwardRef
} from 'react';
// import ReactDOM from 'react-dom';
import styled from 'styled-components';

import {
  Icon,
  Button,
  // notification
} from "antd";

import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { bounceInUp, zoomOutRight, zoomOut } from '../animate';

import { pushKeyedItem } from './collection';



function CloseButton({ onClose }) {
  return (
    <Button className="close"
      onClick={(e) => {
        if (onClose) {
          if (e) {
            e.stopPropagation();
          }

          onClose();
        }
      }}
    >
      <Icon type="close" />
    </Button>
  );
}

class Timer {
  constructor(callback, elapsed) {
    this._elapsed = elapsed;
    this._callback = callback;
    this._timerId = null;
  }

  start() {
    if (this._timerId != null)
      return;

    this._timerId = setTimeout(this._callback, this._elapsed);
  }

  clear() {
    if (this._timerId == null)
      return;

    this._timerId = null;
    clearTimeout(this._timerId);
  }
}

function Notice(props) {

  const {
    message,
    description,
    type,
    duration,
    onClose
  } = props;

  useEffect(() => {
    if (duration === 0 || onClose === undefined)
      return;

    if (duration === undefined) {
      setTimeout(onClose, 5000);
    } else {
      setTimeout(onClose, duration);
    }

  }, [duration, onClose]);

  const elemRef = useRef();
  useEffect(() => {
    const timer = new Timer(onClose, 1500);


    const handleMouseEnter = (event) => {
      timer.clear();
    };

    const handleMouseLeave = (event) => {
      timer.start();
    };

    const elem = elemRef.current;
    elem.addEventListener('mouseenter', handleMouseEnter);
    elem.addEventListener('mouseleave', handleMouseLeave);
    return () => {
      elem.removeEventListener('mouseenter', handleMouseEnter);
      elem.removeEventListener('mouseleave', handleMouseLeave);

      timer.clear();
    };
  }, [elemRef, onClose]);


  return (
    <div ref={elemRef} className={['notice', type].join(' ')}>
      <div className="header">
        <span className="message">{message}</span>
        <CloseButton onClose={onClose} />
      </div>
      <div className={`description`}>{description}</div>
    </div>
  );
}


function Status(props) {

  const {
    message,
    duration,
    type,
    onClose
  } = props;

  useEffect(() => {
    if (duration === 0)
      return;

    console.assert(typeof duration === 'number');

    setTimeout(onClose, duration);
  }, [message, duration, type, onClose]);

  const elemRef = useRef();
  useEffect(() => {
    if (duration === 0) // 禁用超时自动关闭消息
      return;

    const timer = new Timer(onClose, 1500);

    const handleMouseEnter = (event) => {
      timer.clear();
    };

    const handleMouseLeave = (event) => {
      timer.start();
    };

    const elem = elemRef.current;
    elem.addEventListener('mouseenter', handleMouseEnter);
    elem.addEventListener('mouseleave', handleMouseLeave);
    return () => {
      elem.removeEventListener('mouseenter', handleMouseEnter);
      elem.removeEventListener('mouseleave', handleMouseLeave);

      timer.clear();
    };
  }, [elemRef, message, duration, type, onClose]);

  let icon = null;
  if (type === 'processing') {
    icon = <Icon type="loading" spin />
  } else if (type === "success") {
    icon = <Icon type="check-circle" />
  } else if (type === "error") {
    icon = <Icon type="close-circle" />
  }

  return (
    <div ref={elemRef} className={['status', type].join(' ')}>
      <span className="message">{icon} {message}</span>
      <CloseButton onClose={onClose} />
    </div>
  );
}


const NotificationContext = React.createContext({
  noticeList: [],
  statusList: [],
  pushNotice: () => { },
  removeNotice: () => { },
  pushStatus: () => { },
  removeStatus: () => { },
});

let noticeSN = 1;
function newNoticeKey() {
  return `notice-${noticeSN++}`;
}


function Notification(props) {
  const {
    noticeList,
    statusList,
    removeNotice,
    removeStatus
  } = React.useContext(NotificationContext);

  const { className } = props;

  return (
    <React.Fragment>
      <TransitionGroup className={['status-list', className].join(' ')}>
        {statusList.map(
          (status) =>
            <CSSTransition classNames="status" key={status.key} timeout={500}>
              <Status {...status}
                onClose={() => { 
                  removeStatus(status.key); 
                }}
              />
            </CSSTransition>
        )}
      </TransitionGroup>

      <TransitionGroup className={['notice-list', className].join(' ')}>
        {noticeList.slice(0).reverse().map(
          (notice) =>
            <CSSTransition classNames="notice" key={notice.key} timeout={500}>
              <Notice {...notice}
                onClose={() => { 
                  removeNotice(notice.key); 
                }}
              />
            </CSSTransition>
        )}
      </TransitionGroup>
    </React.Fragment>
  );
}

const StyledNotification = styled(Notification)`

  &.status-list {
    display: flex;
    align-items: center;
    flex-direction: column;

    position: fixed;
    top: 48px;
    width: 100%;
    height: 0;
    padding: 0;
    box-sizing: border-box;
    text-align: center;

    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
    line-height: 1.1;
    list-style: none;
    z-index: 1010;


  }

  &.status-list > .status {
    /* display: inline-flex; */
    height: 2em;
    min-width: 17em;
    max-width: 40em;

    align-items: center;
  }

  &.status-list > .status:not(:first-child) {
    margin-top: 5px;
  }

  &.status-list > .status > .message {
    flex: 1 1;
  }

  &.status-list > .status > button.close {
    flex: 0 0;
    border-style: none;
    padding: 0 8px;
    background-color: unset;
  }

  &.status-list > .status > button.close,
  &.status-list > .status > button.close:hover,
  &.status-list > .status > button.close:focus,
  &.status-list > .status > button.close:active {
    background-color: unset;
  }

  &.status-list > .status {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  }
  
  &.status-list > .status.processing {
    color: #1890ff;
  }

  &.status-list > .status.info {
  }

  &.status-list > .status.success {
    color: #0e920e;
  }

  &.status-list > .status.error {
    color: #c11616;
  }

  
  &.notice-list {
    position: fixed;
    right: 0px;
    bottom: 12px;

    margin: 0;
    padding: 0;
    width: 25em;
    max-width: calc(100vw - 32px);
    margin-right: 24px;
    box-sizing: border-box;

    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5;
    list-style: none;
    z-index: 1010;
  }

  &.notice-list > .notice {
    position: relative;
    padding: 8px 12px;
    overflow: hidden;
    line-height: 1.5;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  &.notice-list > .notice:not(:first-child) {
    margin-top: 12px;
  }

  &.notice-list > .notice::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 5px;
    top: 0;
    left: 0;
  }

  &.notice-list > .notice.error::before {
    background-color: red;
  }

  &.notice-list > .notice.warning::before {
    background-color: #fbab19;
  }

  &.notice-list > .notice.info::before {
    background-color: #1890ff;
  }

  &.notice-list > .notice.success::before {
    background-color: #54a74f;
  }

  &.notice-list > .notice > .header {
    display: flex;
  }

  &.notice-list > .notice > .header > Button.close {
    border-style: none;
    box-shadow: none;
    padding: 0;
    flex: 0 0;
    color: black;
  }

  &.notice-list > .notice > .header > .message {
    flex: 1 1;
    font-size: 1.2em;
    font-weight: bold;
  }

  &.notice-list > .notice > .description {

  }

  &.notice-list > .notice-enter-active {
    animation-duration: 600ms;
    animation-name: ${bounceInUp};
  }

  &.notice-list > .notice-exit-active {
    animation-duration: 600ms;
    animation-name: ${zoomOutRight};
  }

  &.status-list > .status-enter-active {
    animation-duration: 600ms;
    animation-name: ${bounceInUp};
  }

  &.status-list > .status-exit-active {
    animation-duration: 600ms;
    animation-name: ${zoomOut};
  }  

`;

export function NotificationProvider({ children }) {

  const [noticeList, setNoticeList] = useState([]);
  const [statusList, setStatusList] = useState([]);


  const pushNotice = (notice) => {
    if (notice.key === undefined) { // 消息如果没有Key，按照序号生成新的
      notice = { ...notice, key: newNoticeKey() };
    }

    setNoticeList((list) => pushKeyedItem(list, notice))

    return notice.key;
  }

  const pushStatus = (status) => {

    if (status.key === undefined) { // 消息如果没有Key，按照序号生成新的
      status = { ...status, key: newNoticeKey() };
    }

    setStatusList((list) => pushKeyedItem(list, status));

    return status.key;
  }

  const removeNotice = (key) => {


    setNoticeList((list) => (list.filter((r) => r.key !== key)))
  }

  const removeStatus = (key) => {
    setStatusList((list) => (list.filter((r) => r.key !== key)))
  }

  let contextValue = React.useMemo(
    () => ({
      pushNotice,
      pushStatus,
      removeNotice,
      removeStatus,
      noticeList,
      statusList
    }),
    [noticeList, statusList]
  );

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
      <StyledNotification />
    </NotificationContext.Provider>
  );
}


export function useStatusNotification() {
  const { pushStatus, removeStatus } = React.useContext(NotificationContext);

  const hint = (message, duration, type) => {
    if (duration === undefined)
      duration = 4000;

    if (type === undefined)
      type = "info";

    pushStatus({ message, duration, type });
  }

  const processing = (message) => {

    const key = pushStatus({
      message,
      type: 'processing',
      duration: 0
    });

    return {
      error(message) {
        pushStatus({
          key,
          message,
          type: 'error',
          duration: 4
        });
      },
      success(message) {
        pushStatus({
          key,
          message,
          type: 'success',
          duration: 4
        });
      },
      close() {
        removeStatus(key);
      }
    }
  }

  return {
    hint,
    processing,
    info(message, duration) {
      hint(message, duration, 'info');
    },
    success(message, duration) {
      hint(message, duration, 'success');
    },
    error(message, duration) {
      hint(message, duration, 'error');
    }
  };
}


export function useAsyncExecutor() {
  const notification = useStatusNotification();

  const invoker = (asyncFn) => {

    setTimeout(() => {
      (async () => {
        try {
          const sucessMsg = await asyncFn();
          if (sucessMsg !== undefined)
            notification.success(sucessMsg);
        } catch (err) {
          console.error(err);

          if (err.response !== undefined) {
            const { data, status } = err.response;
            console.error(data);
            if (status >= 500) {
              notification.error(`出现服务错误，请查询日志`);
            }
          }

        }
      })();
    }, 0);
  };

  return invoker;
}


export function useAsyncInvoker() {
  const notification = useStatusNotification();

  const invoker = (processingMsg, asyncFn) => {

    setTimeout(() => {
      (async () => {
        const notifier = notification.processing(processingMsg);
        try {
          const sucessMsg = await asyncFn();
          notifier.close();
          if (sucessMsg !== undefined)
            notification.success(sucessMsg);
        } catch (err) {
          notifier.close();
          console.error(err);

          if (err.response !== undefined) {
            const { data, status } = err.response;
            console.error(data);
            if (status >= 500) {
              notification.error(`在‘${processingMsg}’中，出现错误`);
            }
          }

        }
      })();
    }, 0);
  };

  return invoker;
}
