import React from "react";
import styled from 'styled-components';

import {
    useState,
    useCallback,
} from "react";

import { AsyncLoader } from '../util/panel';
import { loadIndicatorRank } from './model';

function IndicatorRankSheet(props) {

    const { indicator } =  props;

    const [records, setRecords] = useState([]);
    const maxRatio = (records && records.length > 0) ? records[0].ratio : 1;

    const load = useCallback(async () => {
        setRecords(await loadIndicatorRank(indicator));
    }, [indicator]);

    return (
        <Styled>
            <AsyncLoader loader={load}>
                <div className="rank-item header" >
                    <div className="rank">排名</div>
                    <div className="value">{indicator}</div>
                    <div className="ratio">占比</div>
                    <div >名称</div>
                </div>
                <div className="sheet-content" >
                    {records && records.map(({ value, ratio, firm_sn, firm_name }, idx) =>
                        (<div className="rank-item" key={firm_sn}>
                            <div className="rank">{idx + 1}</div>
                            <div className="value">{value.toFixed(2)}</div>
                            <div className="ratio">
                                {ratio && (ratio * 100).toFixed(1) + '%'}
                            </div>
                            <div className="progress">
                                <div className="percent"
                                    style={{
                                        width: `${(ratio / maxRatio * 100).toFixed(2)}%`
                                    }}
                                />
                                <div className="title">{firm_name}</div>
                            </div>
                        </div>)
                    )}
                </div>
            </AsyncLoader>
        </Styled>
    );
}

export default IndicatorRankSheet;

const Styled = styled.div`

    .sheet-content {
        overflow-y: scroll;
        height: calc(100vh - 13em);
        position: relative;
    }

    .rank-item.header {
        font-weight: bolder;
    }

    .rank-item {
        width: 100%;

        display: flex;

        height: 2em;
        line-height: 2em;

        > .rank {
            flex: 3em 0;
            text-align: center;
        }

        > .value {
            /* width: 15em; */
            flex: 8em 0;
            text-align: right;

            padding-right: 1em;
        }
        
        > .ratio {
            flex: 6em 0 ;
            padding-right: 2em;
            text-align: right;
        }

        .progress {
            flex: 1 0;
            margin-left: -1em;

            .percent {
                height: 2em;
                background-color: hsla(6, 64%, 68%, 0.4);
            }

            .title {
                position: absolute;
                text-align: left;
                margin-top: -2em;
                padding-left: 1em;
            }
        }
    }
`;

