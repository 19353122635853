import React from "react";
import styled from "styled-components";
import clsx from "clsx";
import { Link } from "react-router-dom";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import IconButton from "@material-ui/core/IconButton";

export default function Page(props) {
    const { className, children, title } = props;

    return (
        <Styled className={clsx("page", className)}>
            <div className="page-header">
                <div className="backward">
                    <IconButton color="default" component={Link} to="/map/home">
                        <ArrowBackIcon />
                    </IconButton>
                </div>
                <div className="title">{title}</div>
                <div className="extra"></div>
            </div>
            {children}
        </Styled>
    );
}

const Styled = styled.div`
    position: absolute;

    min-width: 50%;
    right: 5px;
    /* margin-left: auto; */
    /* margin-right: auto; */
    padding-top: 48px;

    margin: 10px 30px 10px 30px;
    padding: 20px 20px 10px 20px;

    background-color: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 
                0px 1px 1px 0px rgba(0,0,0,0.14), 
                0px 2px 1px -1px rgba(0,0,0,0.12);
    
    border-radius: 4px;

    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;


    > .page-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 2px 5px;

        display: flex;

        background-color: hsl(0, 100%, 25%);

        user-select: none;

        > .title {
            flex: 1 1;

            font-size: 1.2em;
            letter-spacing: 0.1em;
            color: #f2f2f2;
            font-weight: bold;
            padding-top: 3px;
        }

        > .backward {
            .MuiIconButton-root {
                padding: 3px;
                color: #fff;

                &:hover {
                    color: hsla(207, 100%, 83%, 1);
                }
            }

            margin-right: 0.2em;
        }
    }
`;
