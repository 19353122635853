import React from "react";
import styled from "styled-components";

// import { select as d3Select, mouse as d3Mouse } from "d3-selection";
// import { transition as d3Transition } from "d3-transition";
// import { symbol as d3Symbol, symbolDiamond } from "d3-shape";
// import { bisectRight } from "d3-array";

// import { line } from "d3-shape";

// import d3Tooltip from "../../util/D3Tooltip";
// import Scale from "./Scale";
// import clsx from "clsx";

// export default class Legend {
//     constructor(domNode, config) {
//         this.svg = d3Select(domNode).append("svg");
//         this.svg //
//             .attr("width", "100%")
//             .attr("height", "100%");

//         this.rootDomNode = domNode;

//         // if (config) {
//         //     this.onHighlighted = config.onHighlighted;
//         // }

//         // this.highlighted = { binX: null, binY: null, entity: null };
//     }

//     init(data, dims) {
//         this.svg.append("g")
//     }
// }

const Rect = props => {
    return (
        <svg className="legend-icon">
            <rect x="0" y="0" width="100%" height="100%"></rect>
        </svg>
    );
};

const LegendZdmj = ({ size }) => {
    return (
        <svg
            className="legend-icon"
            style={{ height: `${2 * size}px`, width: `${2 * size}px` }}
        >
            <circle cx={size} cy={size} className="zdmj" r={size} />
        </svg>
    );
};

// d="M0,5.885661912765424L3.398088489694245,0L0,5.885661912765424L-3.398088489694245,0Z"
const LegendZdmjNone = ({ size }) => {
    return (
        <svg
            className="legend-icon"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 10 10"
        >
            <path transform="translate(5, 5)" d="M0,5L-4,0L0,-5L4,0Z" />
        </svg>
    );
};

// cx="449.45789930555554" cy="71.87716363636365"

// .domain([50, 100, 500, 1000])
// .range([3, 5, 10, 15]);

export default function Legend(props) {
    return (
        <Styled {...props}>
            <div className="group tzhfl">
                <div className="title">调整分类</div>
                <div className="item tzhfl1">
                    <Rect /> <div>鼓励类</div>
                </div>
                <div className="item tzhfl2">
                    <Rect /> <div>限制类</div>
                </div>
                <div className="item tzhfl3">
                    <Rect /> <div>淘汰类</div>
                </div>
                <div className="item tzhfl0">
                    <Rect /> <label>未分类</label>
                </div>
            </div>

            <div className="group">
                <div className="title">占地面积(亩)</div>
                <div className="item zdmj">
                    <LegendZdmj size={15} />
                    <div>&ge;500</div>
                </div>
                <div className="item zdmj">
                    <LegendZdmj size={10} />
                    <div>&le;500</div>
                </div>
                <div className="item zdmj">
                    <LegendZdmj size={5} />
                    <div>&le;100</div>
                </div>
                <div className="item zdmj">
                    <LegendZdmj size={3} />
                    <div>&le;50</div>
                </div>
                <div className="item zdmj-none">
                    <LegendZdmjNone />
                    <div>无地</div>
                </div>
            </div>
        </Styled>
    );
}

const Styled = styled.div`
    display: flex;

    .legend-icon {
        margin-right: 0.2em;
    }
    .group:not(:first-child) {
        margin-left: 2em;
    }

    .item:not(:first-child) {
        margin-left: 0.5em;
    }

    .group {
        display: flex;
        align-items: center;


        > .item {
            display: flex;
            align-items: center;
        }
        > .title {
            align-items: center;
            font-size: 0.8em;
            color: #a1a1a1;
        }
    }

    .group.tzhfl {
        svg {
            height: 1em;
            width: 1em;
        }
        .tzhfl0 rect {
            stroke: hsla(217, 74%, 50%, 1);
            fill: hsla(217, 74%, 56%, 0.5);
        }
        .tzhfl1 rect {
            stroke: hsla(132, 82%, 30%, 1);
            fill: hsla(132, 82%, 40%, 0.5);
        }
        .tzhfl2 rect {
            stroke: hsla(43, 87%, 35%, 1);
            fill: hsla(43, 87%, 45%, 0.5);
        }
        .tzhfl3 rect {
            stroke: hsla(10, 82%, 35%, 1);
            fill: hsla(10, 82%, 40%, 0.5);
        }
    }

    .zdmj {
        circle {
            fill: none;
            stroke: #999;
            stroke-width: 1px;
        }
    }

    .zdmj-none {
        svg {
            height: 1em;
            width: 1em;
            path {
                fill: none;
                stroke: #999;
                stroke-width: 1px;
            }
        }
    }
`;
