import React from "react";
// import ReactDOM from 'react-dom';

import * as d3 from "d3";
import styled from 'styled-components';

import Map from 'ol/Map';
import MapView from 'ol/View';

import {
    Vector as VectorLayer,
    VectorTile as VectorTileLayer,
    Heatmap as HeatmapLayer,
} from 'ol/layer';

import {
    Vector as VectorSource,
    VectorTile as VectorTileSource
} from 'ol/source';

import GeoJSON from 'ol/format/GeoJSON';
import MVT from 'ol/format/MVT';
import { Fill, Stroke, Style } from 'ol/style.js';
// import Feature from 'ol/Feature';

import RenderFeature from 'ol/render/Feature';

import {
    // defaults as defaultControls, Control,
    Zoom
} from 'ol/control';

// import { listen as olListen } from 'ol/events';



import { get as getProjection } from 'ol/proj';


import { 
    getHeatPoints,
    getFeatureTzhfl,
    getGeofeaturesOfFirm,
} from './model';



// let featureStyle = new Style({
//     fill: new Fill({
//         color: 'rgba(255, 255, 255, 0.6)'
//     }),
//     stroke: new Stroke({
//         color: '#4fe800',
//         width: 1
//     }),
// });

let highlightStyle = new Style({
    stroke: new Stroke({
        color: '#f00',
        width: 1
    }),
    fill: new Fill({
        color: 'rgba(255,0,0,0.1)'
    }),
});


const featureStyle = new Style({
    stroke: new Stroke({
        color: 'hsl(200, 76%, 38%)',
        width: 1
    }),
    fill: new Fill({
        color: 'rgba(0,0,0,0.01)',
    })
});

const selectedFeatureStyle = new Style({
    stroke: new Stroke({
        color: 'rgba(200,20,20,0.8)',
        width: 2
    }),
    fill: new Fill({
        color: 'rgba(200,20,20,0.2)',
    })
});

// 创建colomap function，输入[0,1]的数
const heatColorMap = d3.interpolateHsl(
    d3.color("rgba(255, 0, 0, 0.8)"),
    d3.color("rgba(0, 0, 255, 0.8)")
);


const geojsonFormat = new GeoJSON();

class GeoMap extends React.Component {
    constructor(props) {
        super(props);

        this.mapRef = React.createRef();

        // 暂时使用EPSG:3857坐标系，但该坐标系坐标范围小于我们的，扩大extent避免wrapX
        let projection = getProjection('EPSG:3857');
        // projection.setExtent([-2e7, -2e7, 5e7, 5e7]); 
        projection.setExtent([1e6, 1e6, 5e7, 5e7]);

        // let mvtFormt = new MVT({featureClass: Feature});
        let mvtFormt = new MVT();
        //this.selection = {};

        // this.selectedFeatures = new Set();

        this.tileSource = new VectorTileSource({
            format: mvtFormt,
            projection: projection,
            maxZoom: 24,
            url: '/api/estate/mvt/{z}/{x}/{y}.pbf',
        });

        let tileLayer = new VectorTileLayer({
            source: this.tileSource,
            style: (feature) => {
                // if (this.selectedFeatures.has(feature)) {
                //     return selectedFeatureStyle;
                // }

                return featureStyle;
                // var selected = !!this.selection[feature.get('estate_sn')];
                // return (selected) ? selectedFeatureStyle : featureStyle;
            }
        });
        this.tileLayer = tileLayer;

        this.blockHeatmapLayer = new VectorLayer({
            source: new VectorSource({
                format: new GeoJSON(),
                wrapX: false, // 禁止水平折叠，否则可能会导致foreachFeatureAtPixel异常
            }),
            style: (feature) => {

                let weight = feature.get('weight');
                if (weight === undefined) {
                }

                let bgColor = heatColorMap(weight);

                let stroke;
                console.log(8888, this.selectedFeatures.has(feature));
                if (this.selectedFeatures.has(feature)) {
                    stroke = new Stroke({
                        color: 'rgba(0,0,0, 0)',
                        width: 2,
                    });
                } else {
                    stroke = new Stroke({
                        color: bgColor,
                        width: 1,
                    });
                }

                let polygon = new Style({
                    fill: new Fill({
                        color: heatColorMap(weight),
                    }),
                    stroke: stroke,
                });

                return polygon;
            }
        });


        this.pointHeatmapLayer = new HeatmapLayer({
            source: new VectorSource({
                format: new GeoJSON(),
                wrapX: false, // 禁止水平折叠，否则可能会导致foreachFeatureAtPixel异常
            }),
            blur: 20,
            radius: 20,
        });


        this.tzhflBlockLayer = new VectorLayer({
            source: new VectorSource({
                format: new GeoJSON(),
                wrapX: false,
            }),
            style: (feature) => {
                console.log(33333, feature);
                let bgColor;

                let tzhfl = feature.get('tzhfl');
                if (tzhfl === '限制类') {
                    bgColor = 'hsla(42, 84%, 49%, 0.83)';
                } else if (tzhfl === '淘汰类') {
                    bgColor = 'rgba(158, 22, 12, 0.72)';
                } else if (tzhfl === '鼓励类') {
                    bgColor = 'rgba(22, 138, 68, 0.72)';
                } else {
                    bgColor = 'hsla(144, 11%, 80%, 0.72)';
                }

                let polygon = new Style({
                    fill: new Fill({
                        color: bgColor,
                    }),
                    // stroke: stroke,
                });

                return polygon;
            }
        });



        // var heatmapLayer = new HeatmapLayer({
        //     source: heatmapSource,
        //     blur: 10,
        //     radius: 10,
        //     weight: function(feature) {
        //       // 2012_Earthquakes_Mag5.kml stores the magnitude of each earthquake in a
        //       // standards-violating <magnitude> tag in each Placemark.  We extract it from
        //       // the Placemark's name instead.
        //       var name = feature.get('name');
        //       var magnitude = parseFloat(name.substr(2));
        //       return magnitude - 5;
        //     }
        // });


        // let estateSource = new VectorSource({
        //     format: new GeoJSON(),
        //     // features: (new GeoJSON()).readFeatures(geojsondata),
        //     wrapX: false, // 禁止水平折叠，否则可能会导致foreachFeatureAtPixel异常
        // });

        // let estateGeoLayer = new VectorLayer({
        //     source: estateSource,
        //     style: function (feature) {
        //         return featureStyle;
        //     }
        // });


        let selectionLayer = new VectorLayer({
            source: new VectorSource({
                format: new GeoJSON(),
            }),
            // source: new VectorSource(),
            style: function (feature) {
                return highlightStyle;
            }
        });

        const controls = [
            new Zoom({
                zoomInTipLabel: '放大',
                zoomOutTipLabel: '缩小',
            }),
        ];

        this.selectionLayer = selectionLayer;


        let olmap = new Map({
            controls: controls, //defaultControls().extend([new EditGeometry()]),
            layers: [
                tileLayer,
                this.tzhflBlockLayer,
                this.blockHeatmapLayer,
                this.pointHeatmapLayer,
                selectionLayer,
            ],
            view: new MapView({
                minZoom: 12,
                maxZoom: 25,
                center: [39501200, 4365554],
                zoom: 15,
            })
        });


        olmap.on('click', (event) => {
            var features = olmap.getFeaturesAtPixel(event.pixel);
            if (!features || features.length === 0) {
                selectionLayer.getSource().clear();
                this.onFeatureClick && this.onFeatureClick(null);
                return;
            }

            // estate_sn和firm_sn以VectorTile层（最低层）为准
            let feature = features[features.length - 1];
            let estateSn = feature.get('estate_sn');
            let firmSn = feature.get('firm_sn');

            if (feature.constructor === RenderFeature) {
                let estateGeojson = feature.get('estate_geojson');
                if (estateGeojson !== undefined) {
                    let source = selectionLayer.getSource();
                    source.clear();

                    let features = geojsonFormat.readFeatures(estateGeojson);
                    source.addFeatures(features);
                    // selectionLayer.setStyle(selectionLayer.getStyle());
                }
            } else {
                let source = selectionLayer.getSource();
                source.clear();

                source.addFeature(feature);
            }

            this.onFeatureClick && this.onFeatureClick({
                estateSn: estateSn,
                firmSn: firmSn,
            });
        });


        this.olmap = olmap;
    }


    // loadHeatmap() {
    //     const blockHeatmapSource = this.blockHeatmapLayer.getSource();
    //     const pointHeatmapSource = this.pointHeatmapLayer.getSource();

    //     blockHeatmapSource.clear();
    //     pointHeatmapSource.clear();

    //     (async () => {
    //         let data = await getHeatmap('固投总额');

    //         let geojson;
    //         let features;

    //         geojson = data.blockHeatmap;
    //         features = blockHeatmapSource.getFormat().readFeatures(geojson);
    //         blockHeatmapSource.addFeatures(features);

    //         geojson = data.pointHeatmap;
    //         features = pointHeatmapSource.getFormat().readFeatures(geojson);
    //         pointHeatmapSource.addFeatures(features);

    //     })();
    // }

    showHeatPoints(indicator) {
        const source = this.pointHeatmapLayer.getSource();
        source.clear();

        if (indicator) {
            (async () => {
                let geojson = await getHeatPoints(indicator);
                let features = source.getFormat().readFeatures(geojson);
                source.addFeatures(features);
            })();
        }
    }

    showTzhflBlock(visible) {
        const source = this.tzhflBlockLayer.getSource();
        source.clear();

        if (visible) {
            (async () => {
                let geojson = await getFeatureTzhfl();
                let features = source.getFormat().readFeatures(geojson);
                source.addFeatures(features);
            })();    
        }
    }

    selectFeatures(selected) {
        if (!selected) {
            return;
        }

        const source = this.selectionLayer.getSource();
        source.clear();

        (async () => {
            let geojson = await getGeofeaturesOfFirm(selected.firmSn);
            let features = source.getFormat().readFeatures(geojson);
            source.addFeatures(features);
            if ( source.getFeatures().length === 0) {
                return;
            }

            const map = this.olmap;

            const view = map.getView();
            const zoom = view.getZoom();
            view.fit(source.getExtent(), map.getSize());
            view.setZoom(zoom);

            // console.info(map.getView().getCenter());
            // console.info(map.getView().getZoom());            

            // console.log(999, source.getFeatures()[0].getGeometry().getExtent());
            // console.log(998, source.getExtent());
        })();

        // const source = this.tileLayer.getSource();
        // // const features = source.getFeatures();
        // console.log(999, Object.keys(source));
        // console.log(992, Object.keys(this.tileLayer.renderer_));
        // console.log(992, Object.keys(this.tileLayer.renderer_.getFeatures));

        // console.log(888, source.getFeatures, this.tileLayer.getFeatures);
    }

    // https://trendct.org/2016/01/22/how-to-choose-a-label-color-to-contrast-with-background/

    componentDidMount() {
        if (this.props.onFeatureClick) {
            if (this.onFeatureClick !== this.props.onFeatureClick) {
                this.onFeatureClick = this.props.onFeatureClick;
            }
        } else {
            this.onFeatureClick = (sel) => {
                console.log(`selected: estate_sn=${sel.estate_sn}, firm_sn=${sel.firm_sn}`);
            };
        }

        const olmap = this.olmap;


        olmap.setTarget(this.mapRef.current);
        // this.loadHeatmap();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.onFeatureClick) {
            if (this.onFeatureClick !== this.props.onFeatureClick) {
                this.onFeatureClick = this.props.onFeatureClick;
            }
        } else {
            this.onFeatureClick = (sel) => {
                console.log(`selected: estate_sn=${sel.estate_sn}, firm_sn=${sel.firm_sn}`);
            };
        }

        if (this.props.selected !== prevProps.selected) {
            // this.showHeatPoints(this.props.heatPointIndicator);
            this.selectFeatures(this.props.selected);
        }        

        if (this.props.heatPointIndicator !== prevProps.heatPointIndicator) {
            this.showHeatPoints(this.props.heatPointIndicator);
        }

        if (this.props.tzhfl !== prevProps.tzhfl) {
            this.showTzhflBlock(this.props.tzhfl);
        }
    }

    render() {
        return (
            <div className="map" ref={this.mapRef} />
        );
    }
}


const StyledMap = styled.div`
    width: 100vw;
    height: calc(100vh);
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    /* min-height: 40vh; */
    background-color: #efefef;

    .map {
        width: 100%;
    }

    .search-bar {
        position: absolute;
        width: 20em;
        height: 2em;
        margin: 0.5em;
        right: 0.5em;
        opacity: 0.6;

        input {
            color: black;
            font-weight: bold;            
        }

        :focus {
            opacity: 1;
        }
    }

`;

export default GeoMap;
