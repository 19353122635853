import React from "react";
import styled from "styled-components";
import axios from "axios";

import { useAsyncFunction } from "../util/async";
import ActionCurtain from "../util/ActionCurtain";

import Statistic from "../common/Statistic";
import TzhflCount from "./TzhflCounts";
import LotProvisionDonutChart from "./LotProvisionDonutChart";

import LotUsageDonutChart from "./LotUsageDonutChart";
import GutouLineChart from "./GutouLineChart";
import MapChart from "./MapChart";
import NeicBarChart from "./NeicBarChart";

export default function ChartGrid(props) {
    const loader = useAsyncFunction(async () => {
        const response = await axios.get(`/api/dashboard/summary`);
        return response.data;
    }, []);

    const { result } = loader;

    const curtain = comp => {
        if (result) {
            return comp();
        } else {
            return <ActionCurtain action={loader} />;
        }
    };

    return (
        <Styled className="chart-grid">
            <div style={{ gridRow: 1, gridColumn: 1 }} className="chart">
                {curtain(() => (
                    <Statistic
                        title="固投总额(万元)"
                        value={result.present["固投总额"]}
                        pastValue={result.last_year["固投总额"]}
                        precision="0"
                    />
                ))}
            </div>

            <div style={{ gridRow: 1, gridColumn: 2 }} className="chart">
                {curtain(() => (
                    <Statistic
                        title="园区总产值(万元)"
                        value={result.present["产值"]}
                        pastValue={result.last_year["产值"]}
                        precision="0"
                    />
                ))}
            </div>

            <div style={{ gridRow: 2, gridColumn: 1 }} className="chart">
                {curtain(() => (
                    <Statistic
                        title="亩均固投总额(万元)"
                        value={
                            result.present["固投总额"] /
                            result.present["占地面积"]
                        }
                        pastValue={
                            result.last_year["固投总额"] /
                            result.present["占地面积"]
                        }
                        precision="2"
                    />
                ))}
            </div>

            <div style={{ gridRow: 2, gridColumn: 2 }} className="chart">
                {curtain(() => (
                    <Statistic
                        title="亩均总产值(万元)"
                        value={
                            result.present["产值"] / result.present["占地面积"]
                        }
                        pastValue={
                            result.last_year["产值"] /
                            result.present["占地面积"]
                        }
                        precision="2"
                    />
                ))}
            </div>

            <div style={{ gridRow: 1, gridColumn: 5 }} className="chart">
                {curtain(() => (
                    <Statistic
                        title="占地面积(亩)"
                        value={result.present["占地面积"]}
                        precision="0"
                    />
                ))}
            </div>
            <div style={{ gridRow: 1, gridColumn: 6 }} className="chart">
                {curtain(() => (
                    <Statistic
                        title="企业总数"
                        value={result.present["企业总数"]}
                        precision="0"
                    />
                ))}
            </div>

            <div style={{ gridRow: 2, gridColumn: 5 }} className="chart">
                {curtain(() => (
                    <Statistic
                        title="园区税收总额"
                        value={result.present["税收情况"]}
                        pastValue={result.last_year["税收情况"]}
                        precision="0"
                    />
                ))}
            </div>

            <div style={{ gridRow: 2, gridColumn: 6 }} className="chart">
                {curtain(() => (
                    <Statistic
                        title="园区职工人数"
                        value={result.present["职工人数"]}
                        pastValue={result.last_year["职工人数"]}
                        precision="0"
                    />
                ))}
            </div>

            <div style={{ gridRow: "3 / span 2", gridColumn: " 1 / span 2" }}>
                {curtain(() => (
                    <TzhflCount tzhfl_counts={result.tzhfl_counts} />
                ))}
            </div>
            
            <div style={{ gridArea: "5 / 1 / span 2 / span 2" }}>
                <NeicBarChart />
            </div>

            <div style={{ gridRow: "3 / span 2", gridColumn: " 5 / span 2" }}>
                {curtain(() => (
                    <LotProvisionDonutChart data={result.lot_provision} />
                ))}
            </div>

            <div style={{ gridRow: "5 / span 2", gridColumn: " 5 / span 2" }}>
                {curtain(() => (
                    <LotUsageDonutChart data={result.lot_usage} />
                ))}
            </div>

            <div style={{ gridArea: "1 / 3 / span 4 / span 2" }}>
                <MapChart />
            </div>
            <div style={{ gridRow: "5 / span 2", gridColumn: " 3 / span 2" }}>
                <GutouLineChart />
            </div>
        </Styled>
    );
}

const Styled = styled.div`
    display: grid;
    grid-template-columns: 12em 12em 1fr 1fr 12em 12em;
    grid-template-rows: 7em 7em 1fr 1fr 1fr 1fr;
    grid-gap: 10px 10px;

    > div {
        height: 100%;
        width: 100%;
        padding: 5px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .statistic {
        height: 100%;
        .title {
            height: 30%;
        }

        .growth {
            font-size: 0.8em;
        }

        font-size: 1.5em;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
`;
