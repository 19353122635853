import { useState, useEffect, useRef, useMemo } from "react";

export default function useElementSize(elemRef) {
    const ref = useRef(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (!elemRef.current) {
            return;
        }

        // console.log(111233444, elemRef.current instanceof HTMLElement);

        if (!(elemRef.current instanceof HTMLElement)) {
            throw new TypeError(
                `The element against useElementSize should be a HTML element`
            );
        }

        ref.current = elemRef.current;

        if (typeof ResizeObserver === "function") {
            let resizeObserver = new ResizeObserver(entries => {
                console.log(11113333, ref.current.clientHeight);
                setWidth(ref.current.clientWidth);
                setHeight(ref.current.clientHeight);
                // return false;
            });
            resizeObserver.observe(ref.current);
            return () => {
                resizeObserver.unobserve(ref.current);
                resizeObserver = null;
            };
        } else {
            setWidth(ref.current.clientWidth);
            setHeight(ref.current.clientHeight);

            const handleResize = () => {
                setWidth(ref.current.clientWidth);
                setHeight(ref.current.clientHeight);
            };
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }
    }, [elemRef]);

    return useMemo(() => {
        return { width, height };
    }, [width, height]);
}
