import React from "react";
import styled from "styled-components";

import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Link } from "react-router-dom";

import ChartGrid from "./ChartGrid";

export default function HomePage(props) {
    return (
        <StyledPage className="dashboard">
            <div className="header">
                <span className="title">开发区概况</span>
                <span className="close-btn">
                    <IconButton color="default" component={Link} to="/map/home">
                        <ArrowForwardIcon fontSize="large" />
                    </IconButton>
                </span>
            </div>
            <ChartGrid />
        </StyledPage>
    );
}

const StyledPage = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: hsl(224, 26%, 28%);
    user-select: none;

    @media (max-width: 1200px) {
        font-size: 0.85em;
    }

    @media (max-width: 960px) {
        font-size: 0.65em;
    }

    .header {
        height: 5em;
        padding: 10px 5em 5px 5em;
        
        > .title {
            font-size: 2em;
            color: #fff;
        }

        > .close-btn {
            font-size: 2em;
            .MuiIconButton-root {
                color: #fff;
            }
        }
    }

    .chart-grid {
        height: calc(100% - 5em - 1em );
        padding: 0 5em;
}    }

    .chart-grid > div {
        background-color: hsla(226, 25%, 24%, 1);
    }

    .statistic {
        letter-spacing: 0.05em;

        .title {
            color: hsl(180, 5%, 96%);
        }

        .value {
            color: #fff;
        }

        .growth.increase {
            color: hsla(119, 83%, 45%, 1);
        }
    }
`;
