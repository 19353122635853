import React, { useEffect } from "react";
import { useState, useCallback } from "react";

import styled from "styled-components";
import { Switch, Route } from "react-router-dom";

import GeoMap from "./GeoMap";
import FeaturePanel from "./FeaturePanel";

import HomeDash from "../menu/HomeDash";
import RankPage from "../trend/RankPage";
import TrendPage from "../trend/TrendPage";
import NeicPage from "../trend/NeicPage";

import DevelopDistPage from "../trend/DevelopDistPage";

import bgImg from "../images/huabiao.png";

import MapLegend from "./MapLegend";
import useMenuReducer from "../menu/useMenuReducer";
import SearchBox from "../menu/SearchBox";

function MainMap(props) {
    const [menuState, menuDispatch] = useMenuReducer();

    const [selectedFeatures, setSelectedFeatures] = useState(null);
    const [estateSn, setEstateSn] = useState(null);
    const [firmSn, setFirmSn] = useState(null);
    const [tzhflVisible, setTzhflVisible] = useState(false);

    const [heatmapIndicator, setHeatIndicator] = useState(null);

    useEffect(() => {
        let { indicator, mean } = menuState.heatmap;
        if (indicator) {
            if (mean) {
                indicator = "亩均" + indicator;
            }
        }
        setHeatIndicator(indicator);
    }, [menuState.heatmap]);

    useEffect(() => {
        let { selected } = menuState.tzhfl;

        setTzhflVisible(selected.indexOf("限制类") !== -1);
    }, [menuState.tzhfl]);

    const handleFeatureClick = useCallback(
        feature => {
            if (feature && feature.estateSn !== estateSn) {
                const { estateSn, firmSn } = feature;
                setEstateSn(feature.estateSn);
                setFirmSn(feature.firmSn);

                menuDispatch({ type: "SELECT_FEATURE", estateSn, firmSn });
            } else {
                setEstateSn(null);
                setFirmSn(null);
                menuDispatch({
                    type: "SELECT_FEATURE",
                    estateSn: null,
                    firmSn: null
                });
            }
        },
        [estateSn]
    );

    const handleSelectedFirm = useCallback(searchResult => {
        setFirmSn(searchResult.firm_sn);
        setEstateSn(null);

        setSelectedFeatures({ firmSn: searchResult.firm_sn });
    }, []);

    return (
        <StyledMap>
            <div className="map-background" />

            <GeoMap
                onFeatureClick={handleFeatureClick}
                heatPointIndicator={heatmapIndicator}
                selected={selectedFeatures}
                tzhfl={tzhflVisible}
            />

            <MapLegend
                heatmapIndicator={heatmapIndicator}
                tzhfl={menuState.tzhfl}
            />

            {(estateSn || firmSn) && (
                <FeaturePanel
                    estateSn={estateSn}
                    firmSn={firmSn}
                    onClose={() => setEstateSn(null)}
                />
            )}

            <div className="page-container">
                <Switch>
                    {/* <Route path="/map/devdist" component={DevelopDistPage} /> */}
                    <Route
                        path="/map/devdist"
                        render={() => (
                            <DevelopDistPage
                                onFirmSelected={handleSelectedFirm}
                            />
                        )}
                    />

                    <Route path="/map/rank" component={RankPage} />
                    <Route path="/map/trend" component={TrendPage} />
                    <Route path="/map/neic" component={NeicPage} />
                    <Route
                        path="/map/home"
                        render={() => <HomeDash state={menuState} />}
                    />
                </Switch>
            </div>

            <SearchBox
                state={menuState}
                dispatch={menuDispatch}
                onSelect={handleSelectedFirm}
            />
        </StyledMap>
    );
}

export default MainMap;

const StyledMap = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    background-color: #f2f2f2;

    .map-background {
        position: absolute;
        background-image: url(${bgImg});
        background-repeat: no-repeat;
        background-size: contain;

        width: 100%;
        height: 70%;
        background-image: url(${bgImg});
        background-repeat: no-repeat;
        background-size: contain;
        bottom: -5%;
        left: -5%;
        filter: hue-rotate(300deg);
        opacity: 0.3;
    }

    .page-container {
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;

        .page {
            pointer-events: all;
        }
    }

    .map {
        width: 100%;
    }
`;
