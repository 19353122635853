import React from "react";
import {
    useState,
    useCallback,
} from "react";

import { AsyncLoader } from '../util/panel';

import Statistic from '../common/Statistic';

// import * as d3 from "d3";

import styled from 'styled-components';

import { getFeatureEstate } from './model';

function FeatureEstate(props) {

    const { estateSn } = props;
    const [estate, setEstate] = useState(null);

    const load = useCallback(async () => {
        let estate = await getFeatureEstate(estateSn);
        setEstate(estate);
    }, [estateSn]);

    return (
        <StyledFeature className="feature">
            <AsyncLoader loader={load}>
                {estate && <EstateBreif estate={estate} />}
            </AsyncLoader>
        </StyledFeature>
    )
}

const StyledFeature = styled.div`
    margin: 10px 0;
    padding: 15px 10px;
    background-color: #fff;
    box-shadow: 2px 2px #ccc;
    height: 6.5em;
`;

function EstateBreif(props) {
    const { zdmj, dlmc, gylx, lot_jzhmd, lot_rjl, lot_lhl } = props.estate;

    return (
        <StyledBrief className="estate-brief">
            <div className="labels">
                {dlmc && <div className="ant-tag dlmc">{dlmc}</div>}
                {gylx && <div className="ant-tag gylx">{gylx}</div>}
            </div>
            <div className="metrics">
                <Statistic title="占地面积(亩)" value={zdmj} precision="2" />
                <Statistic title="建筑密度" value={lot_jzhmd} precision="2" />
                <Statistic title="容积率" value={lot_rjl} precision="2" />
                <Statistic title="绿化率" value={lot_lhl} precision="2" />
            </div>
        </StyledBrief>
    );
}
const StyledBrief = styled.div`
    > .labels {
        display: flex;
        justify-content: space-between;
        margin: 0 0 10px 0;

        > .ant-tag {
            color: #fff;
            border-radius: 2px;
            padding: 2px 5px;
            font-size: 0.9em;
            font-weight: bold;

            &.dlmc {
                background-color: hsl(204, 70%, 43%);
            }

            &.gylx {
                background-color: hsl(204, 70%, 43%);
            }
        }
    }

    > .metrics {
        display: flex;
        justify-content: space-around;
        margin: 10px 0;

        > div {
            /* flex: 22% 0; */
        }
        
    }

`;

export default FeatureEstate;
