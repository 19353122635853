import React from 'react';
import styled from 'styled-components';

import {
    Icon,
} from 'antd';


// import Number from './Number';

const TOLERANCE = 1e-6;

function parseNumber(value, precision) {


    const valueString = String(value);
    const cells = valueString.match(/^(-?)(\d*)(?:\.(\d+))?$/);

    if (cells === null) {
        return null;
    }


    const negative = cells[1];
    let integer = cells[2] || '0';
    let fraction = cells[3] || '';

    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (typeof precision === 'number') {
        fraction = fraction.padEnd(precision, '0').slice(0, precision);
    }

    return {
        negative: negative,
        integer: integer,
        fraction: fraction,
    };
}


export default function Statistic(props) {
    const { title, value, pastValue } = props;

    let { precision, percentPrecision } = props;
    if (precision === undefined) {
        precision = 2;
    } else {
        precision = parseInt(precision);
    }

    if (percentPrecision === undefined) {
        percentPrecision = 2;
    }

    let valueNode = null;
    let growthNode = null;

    if (typeof value === 'number') {

        let className = 'value';

        let number = parseNumber(value, precision);
        if (number === null) {
            className += ' null';
            number = {
                negative: '',
                integer: '0',
                fraction: ''
            };
        }

        const valueColSpan = (precision > 0) ? "1" : "2";
        console.log(8888, precision, valueColSpan);
        valueNode = (
            <tr className={className} >
                <td className="integer" colSpan={valueColSpan}>
                    <span className="integer">
                        {number.negative}
                        {number.integer}
                    </span>
                </td>
                {precision > 0 && <td className="fraction">
                    <span>.</span>
                    <span className="fraction">
                        {number.fraction}
                    </span>
                </td>}
            </tr>
        );

        if (typeof pastValue === 'number') {
            // 计算并绘制增长率
            let percent = (value - pastValue) / pastValue * 100;

            // const growthFormat = parseNumber(percent, percentPrecision);
            let prefix;
            let className = "growth";
            let number = parseNumber(percent, percentPrecision);
            if (number === null) {
                className += ' null';
                number = {
                    negative: '',
                    integer: '0',
                    fraction: ''
                };
            } else {
                if (Math.abs(percent) < TOLERANCE) {
                    className += ' nochange';
                    prefix = <span className="prefix icon"></span>
                } else if (percent > 0) { // arrow-up, caret-up
                    className += ' increase';
                    prefix = <Icon className="prefix" type="arrow-up" />;

                } else {
                    className += ' decrease';
                    prefix = <Icon className="prefix" type="arrow-down" />;
                    number.negative = ''; // 无需显示负号
                }
            }

            growthNode = (
                <tr className={className}>
                    <td className="integer">
                        {prefix}
                        <span className="integer">
                            {number.negative}{number.integer}
                        </span>
                    </td>
                    <td className="fraction">
                        <span>.</span>
                        <span className="fraction">{number.fraction}</span>
                        <span className="postfix">%</span>
                    </td>
                </tr>
            );
        } else if (pastValue === null) {
            growthNode = (
                <tr className="growth invalid">
                    <td colSpan="2"><span>无同期数据</span></td>
                </tr>
            );
        }
    } else {
        valueNode = (
            <tr className="value invalid">
                <td colSpan="2"><span>无数据</span></td>
            </tr>
        );
    }

    let className = 'statistic';
    if (typeof props.className === 'string') {
        className += ' ' + props.className;
    }

    return (
        <StyledDiv className={className}>
            <div className="title">{title}</div>
            <div className="content">
                <table><tbody>
                    {valueNode}
                    {growthNode}
                </tbody></table>
            </div>
        </StyledDiv>
    );
}


const StyledDiv = styled.div`

    width: fit-content;
    display: flex;
    flex-direction: column;

    > .title {
        font-size: 0.6em;
        color: #a1a1a1;
        text-align:center;
        flex: 0 0;
    }

    > .content {
        flex: 1 0;
        display: flex;
        justify-content: center;        
        > table {
            height: 100%;
        }
    }

    .value {
        color: hsla(0, 0%, 0%, 0.8);
        font-weight: bold;
        line-height: 1.2em;
    }



    td {
        padding: 0;
    }

    td.integer {
        text-align: right;
        min-width: 60%;
    }

    td.fraction {
        text-align: left;
        font-size: 0.7em;

        span {
            vertical-align: sub;
        }
    }

    tr.growth {
        line-height: 1em;
        font-size: 0.7em;
    }

    /* .growth {
        font-size: 0.7em;
        line-height: 1em;

        .prefix {
            
        }

        .postfix {
            font-size: 0.65em;
            margin-left: 2px;
        }
    } */


    .growth.increase {
        color: hsl(119, 83%, 27%);
    }

    .growth.decrease {
        color: hsl(0, 85%, 49%);
    }

    .growth.nochange {
        color: hsla(119, 2%, 50%, 1);

        .prefix.icon {
            display: inline-block;
            position: relative;
            width: 1em;
            height: 1em;

            ::before {
                content: '=';
                text-align: center;
                position: relative;
                font-size: 1.2em;
                line-height: 1.2em;
                vertical-align: bottom;             
            }
        }


    }

    .value.null,  .growth.null {
        color: hsla(0, 2%, 66%, 0.55);
    }

    .value.invalid {
        color: hsla(0, 2%, 66%, 0.55);
        font-weight: normal;
        line-height: 1.2em;
        font-size: 0.8em;
        td {
            text-align: center;
        }
    }

    .growth.invalid {
        font-size: 0.6em;
        color: hsla(119, 2%, 50%, 1);
        text-align: right;
        
        span {
            vertical-align: middle;
        }
    }

    

    .number {
        font-size: 1em;
        line-height: 1.1em;
        
        .integer {
            
        }

        .fraction {
            font-size: 0.65em;
        }

    }

`;