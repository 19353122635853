import { select as d3Select } from "d3-selection";
import { axisBottom, axisRight } from "d3-axis";
import { scaleLinear, scaleThreshold } from "d3-scale";
// import { extent as arrayExtent } from "d3-array";

// import Axes from "./Axes";

export default class Axis {
    constructor(domNode) {
        this.svg = d3Select(domNode).append("svg");
        this.svg //
            .attr("width", "100%")
            .attr("height", "100%");

        this.rootDomNode = domNode;
    }

    init(data, dims) {
        this.data = data;
        this.dims = dims;
        this.chart = this.svg.append("g");
        this.chart.attr(
            "transform",
            `translate(${this.dims.margin.left}, ${this.dims.margin.top})`
        );

        this.xScale = scaleLinear()
            .domain([0, 100])
            .range([0, dims.innerWidth]);

        this.xAxisBottom = axisBottom()
            .scale(this.xScale)
            .ticks(10);

        this.chart.call(this.xAxisBottom);

        // this.axes = new Axes(this.chart, data, this.dims); //second

        // this.updateData(data, this.axes);
    }

    updateData(data) {
        //
    }

    updateDims(dims) {
        //
    }
}
