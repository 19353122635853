import { bisector } from "d3-array";

/**
 *
 * @param {*} d
 */
export function ecdf(d) {
    let xx, xs, xi;

    // keep index order
    xx = d.map((v, i) => {
        return { v, i };
    });

    // ignore null and sort
    xs = xx.filter(x => x.v !== null).sort((a, b) => a.v - b.v);

    // compute percentage
    const bisectRight = bisector(o => o.v).right;
    let count = xs.length;
    xi = xs.map(e => bisectRight(xs, e.v) / count);

    let percents = new Array(xx.length).fill(null);
    for (let k = xs.length - 1; k >= 0; k--) {
        percents[xs[k].i] = xi[k];
    }

    return percents;
}
