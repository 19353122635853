import React from "react";
import styled from "styled-components";

import ButtonList from "./ButtonList";

import { useUserSession } from "../session/UserContext";

import UserTitle from "./UserTitle";
import SelectHeatmapIndicator from "./SelectHeatmapIndicator";
import SelectTzhfl from "./SelectTzhfl";

export default function MenuPanel(props) {
    const { state, dispatch } = props;

    const session = useUserSession();

    return (
        <Styled className="navigation">
            <SelectHeatmapIndicator
                heatmap={state.heatmap}
                dispatch={dispatch}
            />
            <SelectTzhfl tzhfl={state.tzhfl} dispatch={dispatch} />

            <div className="gutter" />

            <div className="row">
                <ButtonList />
            </div>

            <div className="gutter" />

            <div className="bottom row">
                <div className="user left">
                    <UserTitle />
                </div>
                <div className="right">
                    <button
                        className="link task primary"
                        onClick={() => session.logout()}
                    >
                        退出
                    </button>
                </div>
            </div>
        </Styled>
    );
}

const Styled = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    padding: 10px 20px 5px 20px;

    .header {
        font-size: 1.1em;
        padding-left: 2em;
        user-select: none;
    }

    .menu-section {
        > .title {
            font-size: 0.9em;
            text-shadow: 1px 1px 1px #eee;
        }
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        /* margin: 0 20px; */
    }

    .row > .menu-item {
        flex: 0 0 26%;
        padding: 2px 20px;

        .icon {
            height: unset;

            > img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .gutter {
        flex-grow: 1;
    }

    .bottom {
        display: flex;
        margin-top: 10px;
        align-items: flex-end;

        button {
            line-height: 1;
        }

        .user {
            font-size: 1em;
        }

        .left {
            flex-grow: 1;
        }

        .right {
            display: flex;
        }
    }
`;
