import React, { useCallback, useState } from "react";
import styled from "styled-components";

import axios from "axios";

import { useAsyncFunction, ActionCurtain } from "../../util";

import { ecdf } from "./statutil";

import PlotCanvas from "./PlotCanvas";

import GrowthPlot from "./GrowthPlot";
import BarPlot from "./BarPlot";
import HistPlot from "./HistPlot";

import ScatterAxisX from "./ScatterAxisX";
import ScatterAxisY from "./ScatterAxisY";

import Legend from "./Legend";

// import {
//     extent as arrayExtent,
//     max as arrayMax,
//     range as d3Range,
//     histogram as arrayHistogram
// } from "d3-array";

const GrowthChart = PlotCanvas(GrowthPlot, {
    margin: {
        top: 20,
        left: 20,
        bottom: 20,
        right: 20
    }
});

const TopHistChart = PlotCanvas(BarPlot, {
    side: "top",
    margin: { top: 0, bottom: 0, left: 20, right: 20 }
});

const LeftHistChart = PlotCanvas(HistPlot, {
    side: "left",
    margin: { top: 20, bottom: 20, left: 0, right: 0 }
});

const AxisXCanvas = PlotCanvas(ScatterAxisX, {
    margin: { top: 0, bottom: 0, left: 20, right: 20 }
});

const AxisYCanvas = PlotCanvas(ScatterAxisY, {
    margin: { top: 20, bottom: 20, left: 0, right: 0 }
});

export default function ScatterChart({onFirmSelected}) {
    const [highlight, setHighlight] = useState(null);
    const loader = useAsyncFunction(async () => {
        const resp = await axios.get(`/api/stats/scale-growth`);

        const { data } = resp.data;

        ecdf(data.map(r => r.x)).forEach((percent, i) => {
            data[i].x_percent = percent * 100;
        });

        return data;
    }, []);

    const handleHighlighted = useCallback(event => {
        // console.log(1111, event);
        setHighlight(event);
    }, []);

    const handleDblClick = useCallback(event => {
        console.log("dblclick!111: ", event);
        if(onFirmSelected) {
            onFirmSelected(event);
        }
        // setHighlight(event);
    }, []);

    return (
        <Styled>
            <ActionCurtain action={loader}>
                {loader.result && (
                    <div className="charts">
                        <GrowthChart
                            className="scatter"
                            style={{ gridArea: "2/2" }}
                            data={loader.result}
                            onHighlighted={handleHighlighted}
                            onDblClick={handleDblClick}
                        />

                        <AxisXCanvas
                            style={{ gridArea: "3/2" }}
                            data={loader.result}
                        />
                        <AxisYCanvas
                            style={{ gridArea: "2/3" }}
                            data={loader.result}
                        />
                        <TopHistChart
                            className="hist-x"
                            style={{ gridArea: "1/2" }}
                            data={loader.result}
                            highlight={highlight}
                        />

                        <LeftHistChart
                            className="hist-x"
                            style={{ gridArea: "2/1" }}
                            data={loader.result}
                            highlight={highlight}
                        />

                        <Legend
                            className="legend"
                            style={{ gridArea: "5/2" }}
                        />

                        <div className="xlabel" style={{ gridArea: "4/2" }}>
                            企业固投总额排位
                        </div>
                        <div className="ylabel" style={{ gridArea: "2/4" }}>
                            产值增长%
                        </div>
                    </div>
                )}
            </ActionCurtain>
        </Styled>
    );
}

const Styled = styled.div`
    /* height: 100%; */
    /* width: 100%; */
    font-size: calc(6px + 2vmin);
    width: calc(100vw - 28rem - 1rem);
    height: calc(100vh - 5rem);
    /* position: absolute; */
    .gdp-label {
        fill: #666;
        font-size: 10px;
        font-weight: 600;
        text-anchor: start;
    }

    .xlabel {
        justify-self: center;
        align-self: center;
        font-weight: 600;
        letter-spacing: 0.2em;
    }

    .ylabel {
        justify-self: center;
        align-self: center;
        text-orientation: upright;
        writing-mode: tb;
        letter-spacing: 0.2em;
        font-weight: 600;
    }

    .legend {
        font-size: 0.8em;
        justify-content: flex-end;
    }

    > .charts {
        display: grid;
        height: 100%;

        grid-template-columns: minmax(80px, 1fr) 9fr 20px 30px;
        grid-template-rows: minmax(80px, 1fr) 9fr 20px 30px 30px;

        > .scatter {
        }
    }

    text-align: center;

    .bar {
        fill: hsl(182, 36%, 47%);

        &.highlighted {
            fill: hsla(182, 36%, 60%, 1);
        }
    }

    .firm {
        stroke-width: 1px;

        stroke: hsla(217, 74%, 50%, 1);
        fill: hsla(217, 74%, 56%, 0.5);

        &.tzhfl1 {
        }

        &.tzhfl2 {
            stroke: hsla(43, 87%, 35%, 1);
            fill: hsla(43, 87%, 45%, 0.5);
        }

        &.tzhfl3 {
            stroke: hsla(99, 61%, 35%, 1);
            fill: hsla(123, 95%, 37%, 0.5);
        }

        &.zdmj0 {
            /* stroke: hsla(0, 0%, 0%, 0.6); */
        }
    }

    .zero-line {
        stroke: hsla(0, 62%, 30%, 0.5);
        stroke-width: 1px;
    }

    .quantile-line {
        stroke: #fff;
        stroke-width: 2px;
    }

    .tooltip {
        background-color: rgb(82, 77, 77);
        color: rgb(255, 255, 255);
        padding: 2px 10px;
        font-size: 0.8em;
    }
`;
