
import axios from 'axios';

export async function logout() {
    const res = await axios.post('/api/logout');
    return res.data
}

export async function getUserProfile() {
    const res = await axios.get(`/api/user/profile`);
    return res.data
}