import React, { useCallback } from "react";
import styled from "styled-components";

import Button from "@material-ui/core/Button";

import { Link } from "react-router-dom";

import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";

const indicators = ["固投总额", "产值", "营业收入", "税收情况", "职工人数"];

export default function SelectHeatmapIndicator(props) {
    const { heatmap, dispatch } = props;

    const toggleMean = useCallback(() => {
        dispatch({
            type: "SET_HEATMAP_MEAN"
        });
    }, []);
    const handleClickOption = useCallback(indicator => {
        return () => {
            dispatch({
                type: "SET_HEATMAP_INDICATOR",
                indicator: indicator
            });
        };
    }, []);

    return (
        <StyledHeatSelection>
            <div className="menu-section">
                <div className="title">热点</div>
                <div className="extra">
                    <div onClick={toggleMean}>
                        <Checkbox color="default" checked={heatmap.mean} />
                        <label>亩均</label>
                    </div>
                </div>
            </div>
            <div className="option-list">
                {indicators.map((indicator, idx) => (
                    <Button
                        className={clsx(
                            "option",
                            heatmap.indicator === indicator && "checked"
                        )}
                        key={idx}
                        onClick={handleClickOption(indicator)}
                    >
                        {indicator}
                    </Button>
                ))}
            </div>
        </StyledHeatSelection>
    );
}

const StyledHeatSelection = styled.div`
    margin-bottom: 20px;

    > h3 {
        font-size: 0.8em;
        font-weight: bold;
        color: #999;
    }

    > .row > a {
        padding: 2px 4px;
        font-size: 0.6rem;
        border-radius: 0;
        flex: 0 0 20%;
        text-align: center;
        color: #888;
    }

    .menu-section {
        display: flex;
        > .title {
            flex: 1 1;
        }

        > .extra {
            .MuiCheckbox-root {
                padding: 0;
                .MuiSvgIcon-root {
                    width: 0.8em;
                    height: 0.8em;
                    line-height: 1.5em;
                    margin-right: 3px;
                }
            }
        }
    }

    .option-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > .option {
            padding: 2px 2px;
            font-size: 0.9em;
            min-width: unset;
            flex: 0 0 18%;

            border: 1px solid hsla(0, 0%, 100%, 0);

            &.checked {
                color: rgb(220, 0, 78);
                border: 1px solid rgba(220, 0, 78, 0.5);
            }
        }
    }
`;
