import React from "react";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import * as d3 from "d3";

import Map from "ol/Map";
import MapView from "ol/View";

import {
    Vector as VectorLayer
    // VectorTile as VectorTileLayer,
    // Heatmap as HeatmapLayer
} from "ol/layer";

import { Vector as VectorSource } from "ol/source";
import GeoJSON from "ol/format/GeoJSON";
import { Fill, Stroke, Style } from "ol/style.js";

import { useAsyncFunction } from "../util/async";
import ActionCurtain from "../util/ActionCurtain";

// const neic_colormap = d3.scaleOrdinal(d3.schemeCategory20b);

export default function MapChart(props) {
    const mapElemRef = useRef(null);
    const mapRef = useRef(null);

    const loader = useAsyncFunction(async () => {
        const response = await axios.get(`/api/dashboard/map/features`);
        return response.data;
    }, []);

    useEffect(() => {
        if (!mapRef.current) return;

        const { vectorSource, olmap } = mapRef.current;

        const geojson = loader.result;
        const features = vectorSource.getFormat().readFeatures(geojson);
        vectorSource.addFeatures(features);

        let extent = vectorSource.getExtent();
        olmap.getView().fit(extent, {
            size: olmap.getSize()
        });
    }, [loader.result]);

    useEffect(() => {
        if (!mapElemRef.current) return;

        mapRef.current = makeMap();
        mapRef.current.olmap.setTarget(mapElemRef.current);

        return () => {
            //
        };
    }, []);

    return (
        <StyledDiv ref={mapElemRef} className="geomap">
            {!loader.result && <ActionCurtain action={loader} />}
        </StyledDiv>
    );
}

const StyledDiv = styled.div`
    position: relative;
    height: 100%;
    width: 100%;

    .action-fence {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #1422525e;
        box-shadow: 0px 0px 10px #eeeeee54;

        .title {
            color: hsla(0, 98%, 83%, 1);
        }

        .btn {
            color: hsl(64, 88%, 60%);
        }
    }
`;

const dlmcMap = {
    未达到供地条件的: "#ff7f0e",
    已达到供地条件的: "#2ca02c",
    工矿仓储用地: "#1f77b4",
    商服用地: "#17becf",
    公园与绿地: "#bcbd22",
    住宅用地: "#8c564b",
    其它用地: "#eeeee"
};

function featureStyleFunc(feature) {
    // d3.interpolateRainbow
    // let stroke;
    // console.log(8888, feature.get('neic2_sn'));

    let stroke;
    let strokeColor;
    const dlmc = feature.get("dlmc");
    if (dlmc) {
        strokeColor = dlmcMap[dlmc];
        if (!strokeColor) {
            strokeColor = "#eee";
        }
        stroke = new Stroke({
            color:  strokeColor, //"#4fe800",
            width: 2
        });
    } else {
        stroke = new Stroke({
            color:  "#eee", //"#4fe800",
            width: 2
        });        
    }

    // let stroke = new Stroke({
    //     color:  strokeColor, //"#4fe800",
    //     width: 1
    // });

    let bgColor;
    const neic2_sn = feature.get("neic2_sn");
    
    if (neic2_sn !== null) {
        bgColor = d3.interpolateRainbow(neic2_sn / 20);
    } else {
        bgColor = "rgba(255, 255, 255, 0)";
    }


    let polygon = new Style({
        fill: new Fill({
            color: bgColor
        }),
        stroke: stroke
    });

    return polygon;
}

function makeMap() {
    const vectorSource = new VectorSource({
        format: new GeoJSON(),
        wrapX: false // 禁止水平折叠，否则可能会导致foreachFeatureAtPixel异常
    });

    const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: featureStyleFunc
    });

    const olmap = new Map({
        controls: [], //defaultControls().extend([new EditGeometry()]),
        layers: [vectorLayer],
        view: new MapView({
            minZoom: 12,
            maxZoom: 25,
            // center: [39501200, 4365554],
            zoom: 15
        })
    });

    return { olmap, vectorSource };
}
