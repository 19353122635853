import React from "react";

import axios from "axios";
import { useAsyncFunction } from "../util/async";
import ActionCurtain from "../util/ActionCurtain";
import LineChart from "./LineChart";

async function getTrendData() {
    const response = await axios.get(`/api/dashboard/trend/gutou_chanzhi`);
    return response.data;
}

export default function GutouLineChart(props) {
    const loader = useAsyncFunction(async () => {
        return getTrendData();
    }, []);

    return (
        <ActionCurtain action={loader}>
            <LineChart data={loader.result} />
        </ActionCurtain>
    );
}
