import { select as d3Select, mouse as d3Mouse } from "d3-selection";
import { transition as d3Transition } from "d3-transition";
import { symbol as d3Symbol, symbolDiamond } from "d3-shape";
import { bisectRight } from "d3-array";

// import { line } from "d3-shape";

import d3Tooltip from "../../util/D3Tooltip";
import Scale from "./Scale";
import clsx from "clsx";

export default class GrowthChart {
    constructor(domNode, config) {
        this.svg = d3Select(domNode).append("svg");
        this.svg //
            .attr("width", "100%")
            .attr("height", "100%");

        this.rootDomNode = domNode;

        if (config) {
            this.onHighlighted = config.onHighlighted;
            this.onDblClick = config.onDblClick;
        }

        this.highlighted = { binX: null, binY: null, entity: null };
    }

    init(data, dims) {
        console.log("init: ", dims);
        
        this.data = data;
        this.dims = dims;

        this.scales = new Scale(data, this.dims);
        const { xScale, yScale, xBinRange, yBinRange } = this.scales;

        this.background = this.svg.append("g");
        this.renderBackground(data, dims);

        this.chart = this.svg.append("g");
        this.chart
            .attr(
                "transform",
                `translate(${this.dims.margin.left}, ${this.dims.margin.top})`
            )
            .call(this.handleHighlight.bind(this));
        // this.setLabel();

        this.chart.circles = this.chart
            .append("g")
            .style("pointer-events", "none");

        this.chart.diamonds = this.chart
            .append("g")
            .style("pointer-events", "none");

        this.renderEntities(this.data);
        this.renderTooltip();

        // this.chart
        //     .selectAll(".firm")
        //     .call(this.handleEntityHighlight.bind(this));

        // .call(this.handleHighlight.bind(this));
        this.updateData(data);
    }

    renderBackground(data, dims) {
        const { xScale, yScale } = this.scales;

        this.background
            .append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("height", this.dims.height)
            .attr("width", this.dims.width)
            .style("fill", "#f0f0f0");
        // .style("pointer-events", "all");

        this.background
            .append("line")
            // .attr("class", "zero-line")
            // .style("stroke", "hsla(60, 100%, 60%, 1)")
            .style("stroke", "#fff")
            .style("stroke-width", "2px")
            .attr("x1", 0)
            .attr("x2", this.dims.width)
            .attr("y1", this.dims.margin.top + yScale(0))
            .attr("y2", this.dims.margin.top + yScale(0));

        this.background
            .append("line")
            .style("stroke", "#fff")
            .style("stroke-width", "2px")
            .attr("x1", this.dims.margin.top + xScale(30))
            .attr("x2", this.dims.margin.top + xScale(30))
            .attr("y1", 0)
            .attr("y2", this.dims.height);

        this.background
            .append("line")
            .style("stroke", "#fff")
            .style("stroke-width", "2px")
            .attr("x1", this.dims.margin.top + xScale(80))
            .attr("x2", this.dims.margin.top + xScale(80))
            .attr("y1", 0)
            .attr("y2", this.dims.height);

        this.background
            .append("line")
            .attr("class", "gdp-line")
            .style("stroke", "hsla(30, 100%, 60%, 1)")
            .style("stroke-width", "2px")
            .attr("x1", 0)
            .attr("x2", this.dims.width)
            .attr("y1", this.dims.margin.top + yScale(4.5))
            .attr("y2", this.dims.margin.top + yScale(4.5));

        const gdpText = this.background
            .append("text")
            .attr("class", "gdp-label")
            .attr("x", 5)
            .attr("y",  this.dims.margin.top + yScale(4.5));

        gdpText.append("tspan")
            .attr("alignment-baseline", "after-edge")
            .attr("x", 5)
            .text("天津年均");
        gdpText.append("tspan")
            .attr("alignment-baseline", "after-edge")
            // .attr("x", 5)
            .text("GDP增长");            
    }

    // setLabel() {
    //     const dims = this.dims;

    //     this.chart
    //         .append("text")
    //         .attr("class", "axis-label")
    //         .attr("text-anchor", "end")
    //         .attr("x", dims.innerWidth / 2 + dims.margin.left)
    //         .attr("y", dims.innerHeight + dims.margin.top + 20)
    //         .text("企业百分位数");

    //     this.chart
    //         .append("text")
    //         .attr("class", "axis-label")
    //         .attr("text-anchor", "middle")
    //         .attr("transform", "rotate(-90)")
    //         .attr("y", -dims.margin.left + 20)
    //         .attr("x", -dims.margin.top - dims.innerHeight / 2 + 20)
    //         .text("综合增长指数");
    // }

    updateData(data) {
        //
    }

    updateDims(dims) {
        //
    }

    renderEntities(data) {
        const data1 = data.filter(d => d.zdmj !== null);

        const { xScale, yScale, zdmjScale } = this.scales;

        // .attr({
        //     x1: xScale(0),
        //     y1: yScale(0),
        //     x2: xScale(0),
        //     y2: yScale(0)
        // });

        // line()([[0, 0], [100, 0]]).attr("class", "zero-line");
        const circles = this.chart.circles.selectAll(".firm.zdmj1").data(data1);

        const t = d3Transition().duration(300);

        circles
            .enter()
            .append("circle")
            .attr("class", d =>
                clsx("firm", "zdmj1", d.tzhfl === "限制类" && "tzhfl2")
            )
            .attr("cx", (d, i) => xScale(d.x_percent))
            .attr("cy", d => yScale(d["growth"]))
            .transition(t)
            .delay((d, i) => i * 10)
            .attr("r", d => zdmjScale(d.zdmj));

        circles.exit().remove();

        let delayed = data1.length * 10;

        const dataWithoutZdmj = data.filter(d => d.zdmj === null);

        const markerSymbol0 = d3Symbol()
            .type(symbolDiamond)
            .size(0);

        const markerSymbol1 = d3Symbol()
            .type(symbolDiamond)
            .size(40);

        const diamonds = this.chart.diamonds
            .selectAll(".firm.zdmj0")
            .data(dataWithoutZdmj);

        diamonds
            .enter()
            .append("path")
            .attr("class", "firm zdmj0")
            .attr("transform", function(d) {
                return `translate(${xScale(
                    d.x_percent
                )} , ${yScale(d.growth)})`;
            })
            .attr("d", markerSymbol0)
            .transition()
            .duration(300)
            .delay((d, i) => delayed + i * 30)
            .attr("d", markerSymbol1);
    }

    handleHighlight(node) {
        const { xScale, yScale, xBinRange, yBinRange } = this.scales;

        node.on("mousemove", (_, i, nodes) => {
            const [plotX, plotY] = d3Mouse(nodes[i]);

            const x = xScale.invert(plotX);
            const y = yScale.invert(plotY);

            let binX = bisectRight(xBinRange, x);
            let binY = bisectRight(yBinRange, y);

            if (
                this.onHighlighted &&
                this.highlighted.entity === null &&
                (this.highlighted.binX !== binX ||
                    this.highlighted.binY !== binY)
            ) {
                this.highlighted = { binX, binY, entity: null };
                this.onHighlighted(this.highlighted);
            }
        }).on("mouseout", (_, i, nodes) => {
            const [plotX, plotY] = d3Mouse(nodes[i]);

            const x = xScale.invert(plotX);
            const y = yScale.invert(plotY);

            const [minX, maxX] = xScale.domain();
            const [minY, maxY] = yScale.domain();

            if (
                this.onHighlighted &&
                this.highlighted.entity === null &&
                (x < minX || x > maxX || y < minY || y > maxY)
            ) {
                this.highlighted = { binX: null, binY: null, entity: null };
                this.onHighlighted(this.highlighted);
            }
        });
    }

    renderTooltip() {
        const tool_tip = d3Tooltip()
            .attr("class", "tooltip")
            .rootElement(this.rootDomNode)
            .html(d => d.firm_name)
            .offset([-8, 0]);

        this.chart.call(tool_tip);

        const { xScale, yScale, xBinRange, yBinRange } = this.scales;
        const [minX, maxX] = xScale.domain();
        const [minY, maxY] = yScale.domain();

        this.chart
            .selectAll(".firm")
            .style("pointer-events", "all")
            .on("mouseover", (entity, i, nodes) => {
                tool_tip.show.bind(nodes[i])(entity, i, nodes);

                // let x = xScale(entity.x_percent);
                // let y = yScale(entity.growth);
                let binX = bisectRight(xBinRange, entity.x_percent);
                let binY = bisectRight(yBinRange, entity.growth);

                if (this.onHighlighted) {
                    this.highlighted = { binX, binY, entity: entity };
                    this.onHighlighted(this.highlighted);
                }
            })
            .on("mouseout", (entity, i, nodes) => {
                if (this.onHighlighted) {
                    const [plotX, plotY] = d3Mouse(nodes[i]);

                    let x = xScale.invert(plotX);
                    let y = yScale.invert(plotY);

                    if (x < minX || x > maxX || y < minY || y > maxY) {
                        this.highlighted = {
                            binX: null,
                            binY: null,
                            entity: null
                        };
                    } else {
                        // 离开样本点，但没离开数据图

                        let binX = bisectRight(xBinRange, x);
                        let binY = bisectRight(yBinRange, y);
                        this.highlighted = {
                            binX: binX,
                            binY: binY,
                            entity: null
                        };
                    }

                    this.onHighlighted(this.highlighted);
                }
                tool_tip.hide.bind(nodes[i])(entity, i, nodes);
            })
            .on("dblclick", (firm) => {
                if (this.onDblClick) {
                    this.onDblClick({ firm_sn: firm.firm_sn});
                }
            })

;
    }
}
