
import axios from 'axios';


export async function getHeatmap(indicator) {

    const url  = `/api/heatmap/${indicator}`;
    const res = await axios.get(url);
    return res.data;
}

export async function getHeatPoints(indicator) {

    const url  = `/api/heatpoints/${indicator}`;
    const res = await axios.get(url);
    return res.data;
}


export async function getFeatureEstate(estate_sn) {

    const url  = `/api/geomap/estate/${estate_sn}`;
    const res = await axios.get(url);
    return res.data;
}

export async function getFeatureFirm(firmSn) {

    const res = await axios.get(`/api/geomap/firm/${firmSn}`);
    return res.data;
}

export async function getGeofeaturesOfFirm(firmSn) {

    const response = await fetch(`/api/geofeatures/firm/${firmSn}`);
    return await response.json();
}



export async function getFeatureTzhfl() {

    const res = await axios.get(`/api/geomap/tzhfl`);
    return res.data;
}