import React from 'react'
import styled from 'styled-components';
import {
  Button,
  Icon,
} from 'antd';

function renderAction(key, comp) {

  if (React.isValidElement(comp)) {
    return <span key={key}>{comp}</span>;
  } else if (typeof comp === 'function') {
    return <span key={key}>{comp()}</span>;
  }

  const { icon, title, action, ...props } = comp;

  const handleAction = (e) => {
    if (action) {
      (
        async () => {
          await action();
        }
      )();
    }
  };

  return (
    <Button key={key} onClick={handleAction} {...props}  >
      {icon && <Icon type={icon} />}
      {title}
    </Button>
  );

}

function ActionBar({ className, actions }) {

  return (
    <span className={className}>
      {actions.map((group, index) => (Array.isArray(group)) ? (
        <Button.Group key={`btngrp-${index + 1}`}> {
          group.map((action, index) => (
            renderAction(`btn-${index + 1}`, action)
          ))
        }
        </Button.Group>) :
        (renderAction(`btn-${index + 1}`, group))
      )}
    </span>
  );
}

export default styled(ActionBar)`
  display: flex;

  .ant-btn-group:not(:first-child) {
      padding-left: 8px;    
  }

`;
