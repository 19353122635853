import React from "react";

import styled from 'styled-components';

import FeatureEstate from './FeatureEstate';
import FeatureFirm from './FeatureFirm';

function FeaturePanel(props) {

    const { estateSn, firmSn } = props;

    return (
        <StyledPanel>
            {estateSn && <FeatureEstate estateSn={estateSn} />}
            {firmSn && <FeatureFirm firmSn={firmSn} />}
        </StyledPanel>
    )
}

const StyledPanel = styled.div`
    position: absolute;
    left: 0;
    width: 25rem;
`;

export default FeaturePanel;
