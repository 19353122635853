import React, {
    useState,
    useCallback,
    // useEffect,
    // useReducer
} from "react";

import styled from 'styled-components';

import clsx from 'clsx';

export default function ResultList(props) {

    const { results, onSelect } = props;

    const [selected, setSelected] = useState(null);

    const handleSelect = useCallback((firm) => {
        setSelected(firm);
        if (onSelect) {
            onSelect(firm);
        }
    }, [onSelect]);

    return (
        <Styled className={clsx("search-results", "scroller")} hidden={results.length === 0}>
            {results.map((item, idx) => (
                <div key={idx}
                    className={clsx("result-item", selected === item && 'selected')}
                    onClick={() => handleSelect(item)} >
                    <div className="title">{item.name}</div>
                </div>
            ))}
        </Styled>
    )
}

const Styled = styled.div`
    position: absolute;

    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;

    border-radius: 0 0 8px 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    padding: 20px 0;

    overflow-y: auto;

    max-height: calc(100vh - 60px);

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        > .result-item {
            padding: 4px 10px;
            cursor: pointer;
            width: 100%;

            user-select: none;

            &:hover {
                background-color: #eee;
            }

            &.selected {
                background-color: #eee;
            }
        }

`;
