import React from "react";
import { BrowserRouter } from "react-router-dom";

import { UserSessionProvider } from "./session/UserContext";
import Authenticated from "./session/Authenticated";

import MainView from "./MainView";

export default function App(props) {
    return (
        <BrowserRouter>
            <UserSessionProvider>
                <Authenticated>
                    <MainView />
                </Authenticated>
            </UserSessionProvider>
        </BrowserRouter>
    );
}

