import React from "react";
import { useEffect, useRef } from "react";
import styled from "styled-components";

import * as d3 from "d3";

import { treemap as d3Treemap } from "d3-hierarchy";
import { hierarchy as d3Hierarchy } from "d3-hierarchy";

// import d3Format from "d3-format";

import DOMUid from "./DOMUid";

export default function NeicSunburstChart(props) {
    const { data } = props;

    const elemRef = useRef(null);

    useEffect(() => {
        if (elemRef.current === null || data === null) {
            return;
        }

        const { width, height } = elemRef.current.getBoundingClientRect();

        const dimension = {width, height};

        renderTreeMap(elemRef.current, data, dimension);
    }, [data]);

    return <Styled className="sunburst-chart" ref={elemRef} />;
}

const Styled = styled.svg`
    width: 100%;
    height: 100%;

    .percent {
        fill: #fff;
        font-size: 1.2em;
    }

    .legend text {
        fill: #929daf;
        font-size: 24px;
    }
`;

const format = d3.format(",d");

function renderTreeMap(chartEl, data, dims) {
    const width = dims.width;
    const height = dims.height;

    d3.select(chartEl).select("svg").remove();
    let svg = d3
        .select(chartEl)
        .append("svg")
        .attr("viewBox", [0, 0, width, height])
        .style("font", "10px sans-serif");

    const treemap = data =>
        d3Treemap()
            .size([width, height])
            .paddingOuter(3)
            .paddingTop(19)
            .paddingInner(1)
            .round(true)(
            d3Hierarchy(data)
                .sum(d => d.value)
                .sort((a, b) => b.value - a.value)
        );

    const root = treemap(data);
    const color = d3.scaleSequential([8, 0], d3.interpolateMagma);

    const shadow = DOMUid("shadow");

    svg.append("filter")
        .attr("id", shadow.id)
        .append("feDropShadow")
        .attr("flood-opacity", 0.3)
        .attr("dx", 0)
        .attr("stdDeviation", 3);

    const node = svg
        .selectAll("g")
        .data(
            d3
                .nest()
                .key(d => d.height)
                .entries(root.descendants())
        )
        .join("g")
        .attr("filter", shadow)
        .selectAll("g")
        .data(d => d.values)
        .join("g")
        .attr("transform", d => `translate(${d.x0},${d.y0})`);

    node.append("title").text(
        d =>
            `${d
                .ancestors()
                .reverse()
                .map(d => d.data.name)
                .join("/")}\n${format(d.value)}`
    );

    node.append("rect")
        .attr("id", d => (d.nodeUid = DOMUid("node")).id)
        .attr("fill", d => color(d.height))
        .attr("width", d => d.x1 - d.x0)
        .attr("height", d => d.y1 - d.y0);

    node.append("clipPath")
        .attr("id", d => (d.clipUid = DOMUid("clip")).id)
        .append("use")
        .attr("xlink:href", d => d.nodeUid.href);

    node.append("text")
        .attr("clip-path", d => d.clipUid)
        .selectAll("tspan")
        .data(d => {
            //d.data.name.split(/(?=[A-Z][^A-Z])/g).concat(format(d.value))
            // console.log(3333, d.data.name, d.value);
            return [d.data.name, format(d.value)];
        })
        .join("tspan")
        .attr("fill-opacity", (d, i, nodes) =>
            i === nodes.length - 1 ? 0.7 : null
        )
        .text(d => d);

    node.filter(d => d.children)
        .selectAll("tspan")
        .attr("dx", 3)
        .attr("y", 13);

    node.filter(d => !d.children)
        .selectAll("tspan")
        .attr("x", 3)
        .attr(
            "y",
            (d, i, nodes) =>
                `${(i === nodes.length - 1) * 0.3 + 1.1 + i * 0.9}em`
        );

    return svg.node();
}
