import React from "react";
// import styled from "styled-components";

import DonutCount from "./SimpleDonutChart";

// let labels = ['鼓励类', '限制类', '淘汰类', '未分类'];
// let colors = [
//     'hsl(120, 57%, 40%)', // 鼓励类
//     'hsl(28, 100%, 53%)', // 限制类
//     'hsl(360, 69%, 50%)', // 淘汰类
//     'hsl(205, 71%, 41%)', // 未分类
// ];

export default function DlProvisionDonutChart(props) {
    const { data } = props;

    return (
        <DonutCount
            dataset={data}
            legend={{ fontSize: 18, offset: [-65, -65] }}
        />
    );
}

// const StyledDiv = styled.div`
//   width: 100%;
//   height: 100%;

//   .legend {
//     label {
//       color: #fff;
//     }
//   }
// `;
