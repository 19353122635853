import { select as d3Select } from "d3-selection";

// import d3Tooltip from "../../util/D3Tooltip";
// import Axes from "./Axes";
import { scaleLinear, scaleBand } from "d3-scale";

// import { transition as d3Transition } from "d3-transition";
import {
    extent as arrayExtent,
    max as arrayMax,
    range as d3Range,
    histogram as arrayHistogram
} from "d3-array";

export default class Plot {
    constructor(domNode, config) {
        this.svg = d3Select(domNode).append("svg");
        this.svg //
            .attr("width", "100%")
            .attr("height", "100%");

        this.rootDomNode = domNode;
    }

    init(data, dims) {
        this.dims = dims;

        this.chart = this.svg.append("g");
        this.chart.attr(
            "transform",
            `translate(${this.dims.margin.left}, ${this.dims.margin.top})`
        );

        this.rects = this.chart.append("g");

        this.updateData(data);
    }

    highlight(evt) {
        this.chart.selectAll(".bar").attr("class", (_, i) => {
            return i === evt.binY ? "bar highlighted" : "bar";
        });
    }

    updateData(data) {
        data = data.map(d => d.growth);
        this.data = data;

        const yScale = scaleLinear()
            .domain(arrayExtent(data))
            .range([0, this.dims.innerHeight])
            .nice();

        const histogram = arrayHistogram()
            .domain(yScale.domain())
            .thresholds(10);

        const bins = histogram(data);

        const xScale = scaleLinear()
            .range([0, this.dims.innerWidth])
            .domain([0, arrayMax(bins, d => d.length)]);

        const rects = this.rects
            .selectAll("rect")
            .data(bins)
            .join("rect")
            .attr("class", "bar");
        rects
            .attr("y", d => this.dims.innerHeight - yScale(d.x1))
            .attr("height", d => yScale(d.x1) - yScale(d.x0) - 1)
            .attr("x", (d, i) => this.dims.innerWidth - xScale(d.length))
            .attr("width", d => xScale(d.length));
    }

    updateDims(dims) {
        //
    }
}
