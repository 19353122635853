import { scaleLinear, scaleThreshold } from "d3-scale";
import { extent as d3Extent, range } from "d3-array";

function RoundExtentRange(ext, step) {
    let [a, b] = ext;
    a = Math.floor(a / step) * step;
    b = Math.ceil(b / step) * step;
    a = a === 0 ? 0 : a;
    b = b === 0 ? 0 : b;

    const bins = [];
    for (let i = a; i < b; i += step) {
        bins.push(i);
    }
    return bins.slice(1);
}

export default class Scale {
    constructor(data, dims) {
        this.init(data, dims);
    }

    init(data, dims) {
        this.xScale = scaleLinear()
            .domain([0, 100])
            .range([0, dims.innerWidth]);

        this.zdmjScale = scaleThreshold()
            .domain([50, 100, 500, 2000])
            .range([3, 5, 10, 15, 20]);

        const ext = d3Extent(data.map(d => d.growth));

        this.xBinRange = range(0, 100, 5).slice(1);
        this.yBinRange = RoundExtentRange(ext, 5);

        // console.log(1111, ext, this.yBinRange);

        this.update(data, dims);
    }

    update(data, dims) {
        this.yScale = scaleLinear()
            .domain(d3Extent(data.map(d => d["growth"])))
            .range([dims.innerHeight, 0])
            .nice(10);
    }
}
