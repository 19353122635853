import { select as d3Select } from "d3-selection";

// import d3Tooltip from "../../util/D3Tooltip";
// import Axes from "./Axes";
import { scaleLinear } from "d3-scale";
// import { scaleBand } from "d3-scale";

// import { transition as d3Transition } from "d3-transition";
import {
    extent as arrayExtent,
    max as d3Max,
    range as d3Range,
    sum as d3Sum,
    histogram as arrayHistogram
} from "d3-array";

// function cumsum(values, valueof) {
//     let sum = 0;
//     let index = 0;
//     return Float64Array.from(
//         values,
//         valueof === undefined
//             ? v => (sum += +v || 0)
//             : v => (sum += +valueof(v, index++, values) || 0)
//     );
// }

export default class Plot {
    constructor(domNode, config) {
        this.svg = d3Select(domNode).append("svg");
        this.svg //
            .attr("width", "100%")
            .attr("height", "100%");

        this.rootDomNode = domNode;
    }

    init(data, dims) {
        this.data = data;
        this.dims = dims;

        this.chart = this.svg.append("g");
        this.chart.attr(
            "transform",
            `translate(${this.dims.margin.left}, ${this.dims.margin.top})`
        );

        this.histogram = arrayHistogram()
            .domain([0, 100])
            .thresholds(20)
            .value(d => d.x_percent);

        this.rects = this.chart.append("g");

        this.xScale = scaleLinear()
            .domain([0, 100])
            .range([0, dims.innerWidth])
            .nice();

        // this.scaleLinear()
        //     .domain(d3Range(0, 20))
        //     .thresholds(d3Range(0, 100, 5));

        this.updateData(data);
    }

    highlight(evt) {
        this.chart.selectAll(".bar").attr("class", (_, i) => {
            return i === evt.binX ? "bar highlighted" : "bar";
        });
    }

    updateData(data) {
        const bins = this.histogram(data);

        bins.forEach(bin => {
            bin.sort((a, b) => a.x - b.x);
        });

        let yMax = d3Max(bins, bin => d3Sum(bin, d => d.x));

        this.yBinScale = scaleLinear()
            .domain([0, yMax])
            .range([0, this.dims.innerHeight])
            .nice();

        const { xScale, yBinScale } = this;

        const { innerHeight } = this.dims;

        const bars = this.chart
            .selectAll(".bar")
            .data(bins)
            .enter()
            .append("g")
            .attr("class", "bar")
            .attr("transform", bin => `translate(${xScale(bin.x0)}, ${0})`);

        bars.each((bin, binIdx, nodes) => {
            let total = 0;
            let values = bin.map(r => {
                const v = yBinScale(r.x);
                total += v;
                return { v: v, x: total };
            });

            d3Select(nodes[binIdx])
                .selectAll(".value")
                .data(values)
                .enter()
                .append("rect")
                .attr("class", "value")
                .attr("x", 0)
                .attr("y", d => innerHeight - d.x)
                .attr("width", xScale(bin.x1) - xScale(bin.x0) - 1)
                .transition()
                .duration(300)
                .delay((d, i) => i * 150)
                .attr("height", d => d.v);
        });
    }

    updateDims(dims) {
        //
    }
}
