import React from 'react';
import styled from 'styled-components';

export default function Heading(props) {
    const { 
        // avatar, 
        title, 
        tags, 
        extra, 
        className 
    } = props;
    
    return (
        <StyledHeading className={`heading ${className || ''}`}>
            {title && <div className="title">{title}</div>}
            {tags && <div className="tags">{tags}</div>}
            <div className="fill-remaining">{tags}</div>
            {extra && <div className="extra">{extra}</div>}
        </StyledHeading>
    );
}

const StyledHeading = styled.div`
    display:flex;
    align-items: baseline;
    padding: 20px 0 20px 0;
    
    .fill-remaining {
        flex: auto;
    }
`;
