import { useEffect, useState, useRef, useMemo } from "react";

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function useAsyncFunction(asyncFn, dependencies) {
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(true);
    const promiseRef = useRef(null);
    const [count, setCount] = useState(0);

    useEffect(
        () => {
            const promise = makePromiseCancelable(asyncFn());
            promiseRef.current = promise;
            setProcessing(true);
            promise.promise
                .then(result => {
                    promiseRef.current = null;
                    setProcessing(false);
                    setResult(result);
                })
                .catch(error => {
                    promiseRef.current = null;
                    if (error.isCanceled) {
                        return;
                    }

                    setProcessing(false);
                    setError(error);
                });

            return () => {
                promise.cancel();
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [...dependencies, count]
    );

    return useMemo(() => {
        return {
            result,
            error,
            processing,
            call() {
                setCount(count + 1);
            },
            run() {
                if (error) {
                    setError(null);
                }
                setCount(count + 1);
            }
        };
    }, [result, error, processing, count]);
}

/**
 * 使得Promise提前cancel。并不是真正的cancel正在执行的程序，而是告诉调用者程序已经中断。
 * @param {*} promise
 */
function makePromiseCancelable(promise) {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
            error =>
                hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        }
    };
}
