import React from 'react';
import styled from 'styled-components';


export default function HierarchicalTag(props) {

    let labels = props.label || [];

    return (
        <StyledDiv className="ant-tag hierarchical-tag" >
            {labels.map((label, index) => (
                <li key={index} ><span>{label}</span></li>
            ))}
        </StyledDiv>
    );
}

const menuBgColor = 'hsl(204, 70%, 53%)';

const StyledDiv = styled.ul`
    list-style:none;
    display: flex;
    justify-content: flex-start;
    padding: 0px;
    margin: 0px;

    font-size: 12px;
    
    li {
        span {
            color: #FFF;
            display: block;
            background: ${menuBgColor};
            text-decoration: none;
            position: relative;
            height: 20px;
            line-height:19px;
            text-align: center;
            margin-right: 12px;
        }

        &:first-child{
            span   {
                padding-left: 5px;
                &:before {
                    border:none;
                }
            }
        }
        
        :last-child {
            span {
                padding-right: 5px;
                border-radius: 0 1px 1px 0;
                background-color: ${menuBgColor};

                :after{
                    border-left-color: ${menuBgColor};
                }
                
                :before {
                    border-color: ${menuBgColor};
                    border-left-color:transparent;
                }
            }
        }

        span   {   
            :before, :after{
                content: "";
                position: absolute;
                top: 0;
                border: 0 solid ${menuBgColor};
                border-width: 10px 5px;
                width: 0;
                height: 0;
            }
            
            :before {
                left: -10px;
                border-left-color: transparent;
            }
            
            :after {
                left: 100%;
                border-color:transparent;
                border-left-color:${menuBgColor};
            }
        }
    }
`;