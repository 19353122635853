import React, {
    useState,
    useCallback,
    // useEffect,
    useRef
} from "react";

import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import ClearIcon from "@material-ui/icons/Clear";

import clsx from "clsx";

import CircularProgress from "@material-ui/core/CircularProgress";

export default function SearchBar(props) {
    const {
        searchOpen,
        onSearch,
        onSearchClose,
        menuOpen,
        onMenuOpen,
        className
    } = props;

    const [searching, setSearching] = useState(false);
    const [searchText, setSearchText] = useState("");
    const inputRef = useRef(null);

    const handleSearch = useCallback(
        searchText => {
            if (searching) {
                console.debug("avoid bouncing in search");
                return;
            }

            setSearching(true);
            (async () => {
                await onSearch(searchText);
                setSearching(false);
            })();
        },
        [searching, onSearch]
    );

    const handleChange = useCallback(e => {
        const searchText = e.target.value;
        setSearchText(searchText);
        handleSearch(searchText);
    }, [handleSearch]);

    const handleSearchClose = useCallback(() => {
        if (onSearchClose) {
            onSearchClose();
        }
        setSearchText("");
        inputRef.current.value = "";
    }, [onSearchClose]);

    const handleSubmit = useCallback(
        e => {
            e.preventDefault();
            // console.log(777, searchText)
            handleSearch(searchText);
        },
        [searchText, handleSearch]
    );

    // const handleMenuBtnClick = useCallback(() => {

    // }, []);

    return (
        <StyledSearchBar
            className={clsx(
                "searchbar",
                className,
                (searchOpen || menuOpen) && "open"
            )}
        >
            <div className="menu-btn" onClick={onMenuOpen}>
                {menuOpen ? <MenuOpenIcon /> : <MenuIcon />}
            </div>

            <form onSubmit={handleSubmit}>
                <input
                    name="searchText"
                    type="text"
                    ref={inputRef}
                    placeholder="公司名或关键词"
                    onChange={handleChange}
                />
                <button type="submit" hidden={true} />
            </form>
            <div
                className={clsx("clear-btn", searchOpen && "open")}
                onClick={handleSearchClose}
            >
                <ClearIcon />
            </div>
            <div
                className={clsx("search-btn", searching && "searching")}
                onClick={handleSubmit}
            >
                <SearchIcon />
                {searching && <CircularProgress size={24} />}
            </div>
        </StyledSearchBar>
    );
}

const StyledSearchBar = styled.div`
    .search-input {
    }

    line-height: 1.5;

    transition: all 0.3s;
    display: flex;
    color: rgba(0, 0, 0, 0.65);

    align-items: center;
    padding: 5px;

    .menu-btn {
        height: 24px;
        cursor: pointer;
    }

    .clear-btn {
        width: 24px;
        height: 24px;
        color: #aaa;
        margin-right: 0.5em;

        &.open > .MuiSvgIcon-root {
            display: inline-block;
            cursor: pointer;
        }

        .MuiSvgIcon-root {
            display: none;
        }
    }

    .search-btn {
        height: 24px;
        position: relative;
        cursor: pointer;

        &.searching {
            color: #aaa;
        }

        .MuiCircularProgress-root {
            position: absolute;
            position: absolute;
            right: 0;
            color: #ccc;
        }
    }

    input {
        outline: none;
        width: 100%;
        padding: 4px 11px;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        line-height: 1.5;
        border: none;
        transition: all 0.3s;
        text-overflow: ellipsis;

        ::placeholder {
            color: #ccc;
        }
    }
`;
