import React from 'react';

import styled from 'styled-components';

import HeatmapLegend from './HeatmapLegend';

export default function MapLegend(props) {
    const { heatmapIndicator, tzhfl } = props;

    const tzhflSelected = {};
    for (let option of tzhfl.selected) {
        tzhflSelected[option] = true;
    }

    return (
        <Styled>
            {tzhfl.selected.length > 0 && <div className="row tzhfl">
                <span>产业调整：</span>
                {tzhflSelected['鼓励类'] && <div className="category cat-a">
                    <div className="block" />
                    <div>鼓励类</div> 
                </div>}
                {tzhflSelected['限制类'] && <div className="category cat-b">
                    <div className="block" />
                    <div>限制类</div>
                </div>}
                {tzhflSelected['淘汰类'] && <div className="category cat-c">
                    <div className="block" />
                    <div>淘汰类</div>
                </div>}

                {/* <div className="notes" >选择{tzhflNotes}地块</div> */}
            </div>}
            {heatmapIndicator && <div className="row heatmap">
                <div className="notes" >{heatmapIndicator}热点</div>
                <HeatmapLegend />
            </div>}
        </Styled>
    );
}

const Styled = styled.div`
    position:absolute;
    bottom: 0;
    right: 0;

    background-color: hsla(0,0%,100%,1);
    padding: 5px 2px 2px 5px;
    border-top-left-radius: 4px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .tzhfl {
        .block {
            /* display: inline-block; */
            width: 1em;
            height: 1em;
            /* vertical-align: middle; */
        }

        .category {
            display: flex;
            align-items: center;
            /* margin-left: 1em; */
            margin-right: 1em;
        }
        
        .cat-a > .block {
            background-color: green;
        }

        .cat-b > .block {
            background-color: hsl(41, 78%, 57%);
        }

        .cat-c > .block {
            background-color: hsl(0, 1%, 49%);
        }

    }


    > .row {
        display: flex;


        > .heatmap-legend {
            padding-top: 5px;
            padding-left: 5px;
        }

        > notes {
            color: #666;

            &.heatmap {
                margin-left: 2px
            }
        }
    }
`;
