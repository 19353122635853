import React from "react";
// import styled from 'styled-components';

import DonutCount from "./SimpleDonutChart";

// let labels = ["鼓励类", "限制类", "淘汰类", "未分类"];
let colors = [
    "hsl(120, 57%, 40%)", // 鼓励类
    "hsl(28, 100%, 53%)", // 限制类
    "hsl(360, 69%, 50%)", // 淘汰类
    "hsl(205, 71%, 41%)" // 未分类
];

export default function TzhflCount(props) {
    const { tzhfl_counts } = props;

    return <DonutCount dataset={tzhfl_counts} colors={colors} />;
}
