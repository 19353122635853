import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import * as d3 from "d3";

import useElementSize from "../util/useElementSize";

export default function LineChart(props) {
    const { data } = props;
    const [chart, setChart] = useState(null);
    const containerRef = useRef(null);
    const chartRef = useRef(null);
    const contentSize = useElementSize(containerRef);

    let contentWidth = contentSize.width > 5 ? contentSize.width - 5 : 0;
    let contentHeight = contentSize.height > 5 ? contentSize.height - 5 : 0;

    useEffect(() => {
        if (
            containerRef.current === null ||
            contentWidth === 0 ||
            contentHeight === 0
        ) {
            return;
        }

        const settings = {
            width: contentWidth,
            height: contentHeight,
            marginLeft: 80,
            marginRight: 80,
            marginTop: 20,
            marginBottom: 30
        };

        setChart(makeRender(containerRef.current, settings));
    }, [contentWidth, contentHeight]);

    useEffect(() => {
        if (chart) {
            chart.render(data);
        }
    }, [chart, data]);

    return (
        <Styled ref={containerRef}>
            <svg
                ref={chartRef}
                widht="100%"
                height="100%"
                viewBox={`0 0 ${contentWidth} ${contentHeight} `}
            />
        </Styled>
    );
}

const Styled = styled.div`
    width: 100%;
    height: 100%;

    .line {
        stroke: steelblue;
        stroke-width: 4px;

        &.y1 {
            stroke: hsla(122, 75%, 55%, 1);
        }

        &.y2 {
            stroke: hsla(28, 100%, 63%, 1);
        }
    }

    .axis {
        font-weight: bold;
        font-size: 12px;

        path {
            stroke: #aaa;
            stroke-width: 2px;
        }

        .tick line {
            stroke: #aaa;
            stroke-width: 1px;
        }

        &.x text {
            fill: #eee;
        }

        &.y1 text {
            fill: hsla(122, 75%, 55%, 1);
        }

        &.y2 text {
            fill: hsla(28, 100%, 63%, 1);
        }
    }

    .axis-grid line {
        stroke: hsl(210, 21%, 35%);
    }
`;

// def to_month_no(year, month):
//     return (year - 2000)*12 + month - 1

// def from_month_no(month_no):
//     year = 2000 + month_no // 12
//     month = month_no % 12 + 1
//     return year, month

function formatMonthNo(month_no) {
    const year = 2000 + Math.trunc(month_no / 12);
    const month = (month_no % 12) + 1;
    return `${year}.${month}`;
}

function makeRender(chartEl, settings) {
    let width = settings.width - settings.marginLeft - settings.marginRight;
    let height = settings.height - settings.marginTop - settings.marginBottom;

    d3.select(chartEl)
        .select("svg")
        .remove();

    const svg = d3
        .select(chartEl)
        .append("svg")
        .attr("width", settings.width)
        .attr("height", settings.height)
        .append("g")
        .attr(
            "transform",
            `translate(${settings.marginLeft}, ${settings.marginTop})`
        );

    // parse the date / time
    // var parseTime = d3.timeParse("%d-%b-%y");

    // set the ranges
    var x = d3.scaleTime().range([0, width]);
    var y0 = d3.scaleLinear().range([height, 0]);
    var y1 = d3.scaleLinear().range([height, 0]);

    // define the 1st line
    var valueline1 = d3
        .line()
        .x(d => x(d.x))
        .y(d => y0(d.y1));

    // define the 2nd line
    var valueline2 = d3
        .line()
        .x(d => x(d.x))
        .y(d => y1(d.y2));

    return {
        render(data) {
            if (!data) {
                return;
            }

            x.domain(d3.extent(data.index));

            const newdata = [];
            const series1 = data.series[0];
            const series2 = data.series[1];
            for (let i = 0; i < data.index.length; i++) {
                newdata.push({
                    x: data.index[i],
                    y1: series1[i],
                    y2: series2[i]
                });
            }

            y0.domain(d3.extent(series1));
            y1.domain(d3.extent(series2));

            const xAxis = d3
                .axisBottom(x)
                .ticks(5)
                .tickFormat(formatMonthNo);
            const y0Axis = d3.axisLeft(y0).ticks(4);
            const y1Axis = d3.axisRight(y1).ticks(4);

            svg.append("g")
                .attr("class", "x axis")
                .attr("transform", `translate(0, ${height})`)
                .call(xAxis);

            // Add the Y0 Axis
            svg.append("g")
                .attr("class", "y1 axis")
                .call(y0Axis);

            // Add the Y1 Axis
            svg.append("g")
                .attr("class", "y2 axis")
                .attr("transform", `translate(${width}, 0)`)
                .call(y1Axis);

            const xAxisGrid = d3
                .axisBottom(x)
                .tickSize(-height)
                .tickFormat("")
                .ticks(5);
            const yAxisGrid = d3
                .axisLeft(y0)
                .tickSize(-width)
                .tickFormat("")
                .ticks(4);

            svg.append("g")
                .attr("class", "x axis-grid")
                .attr("transform", "translate(0," + height + ")")
                .call(xAxisGrid)
                .select(".domain")
                .remove();

            svg.append("g")
                .attr("class", "y axis-grid")
                .call(yAxisGrid)
                .select(".domain")
                .remove();

            svg
                .append("path")
                .data([newdata])
                .attr("class", "line y1")
                .style("fill", "none")
                .attr("d", valueline1)
                .call(animatePath);

            // Add the valueline2 path.
            svg.append("path")
                .data([newdata])
                .attr("class", "line y2")
                .style("fill", "none")
                .attr("d", valueline2)
                .call(animatePath);

            // animatePath(path1);
            // animatePath(path2);


        }
    };
}

function animatePath(path) {
    let totalLength= path.node().getTotalLength();
    path
        .attr("stroke-dasharray", `${totalLength} ${totalLength}`)
        .attr("stroke-dashoffset", `${totalLength}`)
        .transition()
        .duration(2000)
        .ease(d3.easeLinear)
        .attr("stroke-dashoffset", 0);
}