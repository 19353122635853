import React from "react";

import styled from "styled-components";

import { Link } from "react-router-dom";

export default function IconButton(props) {
    const { to, title, icon } = props;

    return (
        <Styled className="menu-item">
            <Link to={to}>
                <div className="icon">{icon}</div>
                <div className="title">{title}</div>
            </Link>
        </Styled>
    );
}

const Styled = styled.div`
    box-sizing: border-box;
    border-style: solid;
    border-width: 1px;
    border-color: #ffffff;
    position: relative;

    padding: 5px;

    .icon {
        /* text-align: center; */
        /* height: 64px; */

        > svg {
            width: 100%;
            height: 100%;
        }
    }

    .title {
        text-align: center;
        color: #a1a1a1;
        font-size: 0.8rem;
    }

    &:hover {
        border-style: solid;
        border-width: 1px;
        border-color: #f1f1f1;
    }

    .badge {
        top: 5px;
        right: 5px;
    }
`;
